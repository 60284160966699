import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Button, Select, Spin } from "antd";
import axios from "axios";

const { Option } = Select;

const CreateProfileMeasurementModal = ({ visible, onCancel, profile, onCreate }) => {
  const [form] = Form.useForm();
  const [doctors, setDoctors] = useState([]);
  const [doctorPageIndex, setDoctorPageIndex] = useState(1);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const listInnerRef = useRef();

  useEffect(() => {
    if (visible) {
      setDoctors([]);
      setDoctorPageIndex(1);
      fetchDoctors(1);
    }
  }, [visible]);

  useEffect(() => {
    if (doctorPageIndex > 1) {
      fetchDoctors(doctorPageIndex);
    }
  }, [doctorPageIndex]);

  const fetchDoctors = (page = 1, pageSize = 10) => {
    setLoadingMore(true);
    axios
      .get(`https://visionup.azurewebsites.net/api/Account?RoleID=3&PageIndex=${page}&PageSize=${pageSize}`)
      .then((response) => {
        setDoctors((prevDoctors) => [...prevDoctors, ...response.data.data]);
        setTotalDoctors(response.data.totalCount);
        setLoadingMore(false);
      })
      .catch((error) => {
        console.error("Error fetching doctors:", error);
        setLoadingMore(false);
      });
  };

  const handleCreateProfileMeasurement = (values) => {
    const newProfileMeasurement = {
      ...values,
      profileID: profile.id,
      status: true,
    };

    axios
      .post("https://visionup.azurewebsites.net/api/ProfileMeasurement", newProfileMeasurement)
      .then((response) => {
        console.log("ProfileMeasurement created:", response.data);
        onCreate(response.data);
        form.resetFields();
        onCancel();
      })
      .catch((error) => {
        console.error("Error creating profile measurement:", error);
      });
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !loadingMore &&
        doctors.length < totalDoctors
      ) {
        setDoctorPageIndex((prevPageIndex) => prevPageIndex + 1);
      }
    }
  };

  return (
    <Modal
      title="Create Profile Measurement"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} onFinish={handleCreateProfileMeasurement}>
        <Form.Item
          name="doctorID"
          label="Doctor"
          rules={[{ required: true, message: "Please select a doctor!" }]}
        >
          <Select
            placeholder="Select a doctor"
            style={{ width: "100%" }}
            dropdownRender={(menu) => (
              <div
                onScroll={onScroll}
                ref={listInnerRef}
                style={{ maxHeight: 200, overflowY: "auto" }}
              >
                {menu}
                {loadingMore && (
                  <Spin style={{ display: "block", margin: "10px auto" }} />
                )}
              </div>
            )}
          >
            {doctors.map((doctor) => (
              <Option key={doctor.id} value={doctor.id}>
                {doctor.username} ({doctor.email})
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProfileMeasurementModal;
