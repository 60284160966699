import axios from "axios";

export const useLensService = () => {
    const baseUrl = process.env.REACT_APP_API_URL;


    const getAllLens = async (params = {}) => {
        const queryString = Object.entries(params)
            .filter(([_, value]) => value !== undefined && value !== null)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        const urlWithParams = `${baseUrl}/api/Lens${queryString ? `?${queryString}` : ''}`;
    
        return axios.get(urlWithParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No item found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    const countLens = async (valueCount = false) => {
        const url = `${baseUrl}/api/Lens/count?valueCount=${valueCount}`;
        return axios.get(url)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No count found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    
    const fetchLensType = async () => {
        return axios.get(`${baseUrl}/api/LensType`)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No item found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    const fetchEyeReflactive = async () => {
        return axios.get(`${baseUrl}/api/EyeReflactive`)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No item found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    const createLens = async (lensData) => {
        return axios.post(`${baseUrl}/api/Lens`, lensData)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No item found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    const updateLens = async (lensData) => {
        return axios.put(`${baseUrl}/api/Lens`, lensData)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No item found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    

    const deleteLens = async (id) => {
        try {
            const response = await axios.delete(`${baseUrl}/api/Lens/${id}`);
            if (response.data) {
                return response.data; // Trả về dữ liệu nhận được
            } else {
                console.log('No item found in response');
                throw new Error('No item found in response'); // Throw lỗi nếu không có dữ liệu
            }
        } catch (error) {
            console.error(error); // Log lỗi ra console
            throw error; // Throw lỗi ra ngoài để có thể xử lý ở tầng cao hơn
        }
    };

    
    const fetchLensById = async (id) => {
        return axios.get(`${baseUrl}/api/Lens/${id}`)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No item found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    const validateInput = (data) => {
        let errors = {};
    
        // Helper functions
        const isEmpty = (value) => !value;
        const isOnlyLettersAndSpaces = (value) => /^[A-Za-z\s]+$/.test(value);
        const isPositiveInteger = (value) => Number.isInteger(value) && value > 0;
        const isNumber = (value) => !isNaN(value);
        const isBoolean = (value) => typeof value === 'boolean';
    
        // Validate lensName
        if (isEmpty(data.lensName)) {
            errors.lensName = "Lens name is required.";
        } else if (!isOnlyLettersAndSpaces(data.lensName)) {
            errors.lensName = "Lens name can only contain letters and spaces.";
        }
    
        // Validate lensDescription - Assuming it's optional, no validation needed
    
        // Validate lensPrice
        if (isEmpty(data.lensPrice)) {
            errors.lensPrice = "Lens price is required.";
        } else if (!isNumber(data.lensPrice) || data.lensPrice <= 0) {
            errors.lensPrice = "Lens price must be a number greater than 0.";
        }
    
        // Validate quantity
        if (isEmpty(data.quantity)) {
            errors.quantity = "Quantity is required.";
        } else if (!isPositiveInteger(data.quantity)) {
            errors.quantity = "Quantity must be a positive integer.";
        }
    
        // Validate status
        if (!isBoolean(data.status)) {
            errors.status = "Status must be true or false.";
        }
    
        // Validate lensTypeID
        if (isEmpty(data.lensTypeID)) {
            errors.lensTypeID = "Lens Type ID is required.";
        } else if (!isPositiveInteger(data.lensTypeID)) {
            errors.lensTypeID = "Lens Type ID must be a positive integer.";
        }
    
        // Validate eyeReflactiveID
        if (isEmpty(data.eyeReflactiveID)) {
            errors.eyeReflactiveID = "Eye Reflactive ID is required.";
        } else if (!isPositiveInteger(data.eyeReflactiveID)) {
            errors.eyeReflactiveID = "Eye Reflactive ID must be a positive integer.";
        }
    
        return errors;
    };

    return {
        countLens,
        fetchLensType,
        getAllLens,
        validateInput,
        createLens,
        deleteLens,
        fetchLensById,
        updateLens,
        fetchEyeReflactive
    };
};
