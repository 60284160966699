import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { useToast } from "../../context/ToastContext";
import { useEyeGlassService } from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2/dist/sweetalert2.js'

const SelectLenses = () => {
  const { setToastMessage } = useToast();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { productData } = location.state || {}; // Access the passed state
  const [step, setStep] = useState(1);
  const [selectedLens, setSelectedLens] = useState(null);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [lensTypes, setLensTypes] = useState([]);
  const [lensData, setLensData] = useState(null);
  const [lensOptions, setLensOptions] = useState([]);
  const UserInfo = JSON.parse(localStorage.getItem("UserInfo"));

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const { fetchLensType, getAllLens, createOrder, createProductGlass, createCart } =
    useEyeGlassService();

  const handleLensSelect = (lens) => {
    console.log("Tung", lens);
    let updatedData = { ...originalData, lensData: lens };
    setData(updatedData);
    setSelectedLens(lens);
    if (lens.lensTypeID === 4) {
      setStep(3);
    } else {
      setStep(2);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!productData) {
          navigate("/");
        } else {
          let initialData = {
            ...productData,
            odSphere: 0,
            odCylinder: 0,
            odAxis: 0,
            osSphere: 0,
            osCylinder: 0,
            osAxis: 0,
            pdType: false,
            //address: "",
          };
          setData(initialData);
          setOriginalData(initialData);
        }

        const [lensData, lensTypes] = await Promise.all([
          getAllLens(),
          fetchLensType(),
        ]);

        if (lensData !== null && lensData.data.length > 0 && lensTypes !== null && lensTypes.length > 0) {
          mappingLensTypesWithLensData(lensData.data, lensTypes);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
    };

    fetchData();
  }, [productData, id, navigate]);

  const handleChangeValue = (e, key) => {
    let updatedData = { ...data, [key]: e.target.value };
    setData(updatedData);
  };
  

  const handleSubmit = async () => {
    const canSubmit = validateForm();
    if (!canSubmit) {
      return;
    }
console.log(UserInfo);

    if (!UserInfo) {
      setDisplayModal(false);
    } else {
      const [responseCreateOrder, responsecreateProductGlass] = await Promise.all([
        createOrder(data),
        createProductGlass(data),
      ]);
      if (
        responseCreateOrder.status &&
        responsecreateProductGlass &&
        responsecreateProductGlass.id
      ) {
        toast.success("Order successful");
        navigate("/order-confirm", {
          state: {
            data,
            orderData: responseCreateOrder,
            productGlassData: responsecreateProductGlass,
            typePayment: "oneItem"
          },
        });
      } else {
        setDisplayModal(false);
        toast.error("Order failed");
      }
    }
  };

  const handleAddToCart = async () => {
    if(!UserInfo){
        navigate("/login");
    }

    if (data.lensData.lensTypeID !== 4) {
      const canSubmit = validateForm();
      if (!canSubmit) {
        return;
      }
  }
    console.log("Tung cart", data);
    const [responseCreateCart] = await Promise.all([
      createCart(data)
    ]);
    if (responseCreateCart && responseCreateCart.accountID !== undefined) {
      toast.success("Add to cart successful");
      setTimeout(() => {
        navigate("/");
      }, 1000);
      clearTimeout();
    } else {
      toast.error("Add to cart failed");
    }
  };

const validateForm = () => {
    const {
      odSphere,
      odCylinder,
      odAxis,
      osSphere,
      osCylinder,
      osAxis,
      pdType,
      addOD,
      addOS,
      //address,
    } = data;

    // Skip the specific validation if lensTypeId is 4

        if (
          [
            odSphere,
            odCylinder,
            odAxis,
            osSphere,
            osCylinder,
            osAxis,
            pdType,
            addOD,
            addOS,
          ].some((value) => value < 1)
        ) {
          toast.error("Value must be greater than 0!");
          return false;
        }


    // if (!address.trim()) {
    //   toast.error("Please enter your address!");
    //   return false;
    // }

    return true;
};

  function mappingLensTypesWithLensData(lensData, lensTypes) {
    // Init lens types data
    let mappingLensTypes = [];
    lensTypes.forEach((item) => {
      if (item.status) {
        mappingLensTypes.push({
          id: item.id,
          title: item.description,
          subOptions: lensData.filter((lens) => lens.lensType.id === item.id && lens.status),
        });
      }
    });

    setLensOptions(mappingLensTypes);
  }

  if (loading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }
  const formatVND = (priceInVND) => {
    return priceInVND.toLocaleString('vi-VN');
  };
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <ToastContainer />
      <div className="flex flex-1 items-start justify-between p-12" style={{ backgroundColor: '#F5F5F5' }}>
        <div className="flex-1 flex items-center justify-center">
          <div className="max-w-full">
            <img
              src="https://img.ebdcdn.com/product/lens/gray/mt6827.jpg?im=Resize,width=800,height=400,aspect=fill;UnsharpMask,sigma=1.0,gain=1.0&q=85"
              alt="Glasses"
              className="w-full h-auto mb-8 object-contain"
            />
            <h3 className="text-3xl font-semibold text-center">{data.name}</h3>
            <p className="text-center text-xl text-gray-600">
              {data.glassTypeText}
            </p>
          </div>
        </div>
        <div className="flex-1 bg-white shadow-lg rounded-lg p-8 mx-6 max-w-full w-full">
          {step === 1 && (
            <LensSelection
              lensTypes={lensOptions}
              selectedLens={selectedLens}
              setSelectedLens={setSelectedLens}
              handleLensSelect={handleLensSelect}
              navigate={navigate}
              data={data}
            />
          )}
          {step === 2 && (
            <PrescriptionSelection
              data={data}
              handleChangeValue={handleChangeValue}
              handleSubmit={handleSubmit}
              handleAddToCart={handleAddToCart}
              setStep={setStep}
              displayModal={displayModal}
              setDisplayModal={setDisplayModal}
              UserInfo={UserInfo}
            />
          )}
          {step === 3 && (
            <NonPrescriptionSelection
              // lensTypes={lensOptions.find((lens) => lens.lensTypeID === 4).subOptions}
              selectedLens={selectedLens}
              setSelectedLens={setSelectedLens}
              handleLensSelect={handleLensSelect}
              data={data}
              setStep={setStep}
              handleSubmit={handleSubmit}
              handleAddToCart={handleAddToCart}
              formatVND={formatVND}
            />
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg w-full flex justify-between items-center">
        <div className="flex items-center w-full">
        <div className="relative flex items-center">
        <i className="fas fa-info-circle text-gray-400 hover:text-gray-600 cursor-pointer"></i>
        <div className="absolute hidden group-hover:block bg-black text-white text-sm p-2 rounded-lg" 
            style={{ top: '50%', left: '100%', transform: 'translateY(-50%)', zIndex: '10', whiteSpace: 'nowrap' }}>
          Includes price of the product and selected lens options.
        </div>
      </div>
          {/* Adjusted section for the second half of the screen */}
          <div className="flex-1"></div> {/* This div acts as a spacer */}
          <div className="flex-1 flex justify-start items-center"> {/* Adjusted for alignment */}
            <i className="fas fa-shopping-cart text-gray-400 mr-2"></i> {/* Existing shopping cart icon */}
            <span className="text-lg text-gray-600 mr-10" style={{ fontSize: '16px', color: '#a0a0a0' }}>
              Subtotal:
            </span>
            <div>
          <del style={{ color: '#a0a0a0' }}>{formatVND(data.price + (data.lensData?.lensPrice || 0) + 100000)} VND</del>{" "}
          <span style={{ color: '#000', fontSize: '20px', fontWeight: 500 }}>
            {formatVND(data.price + (data.lensData?.lensPrice || 0))} VND
          </span>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LensSelection = ({
  lensTypes,
  selectedLens,
  setSelectedLens,
  handleLensSelect,
  navigate,
  data,
}) => {
  const [expandedLens, setExpandedLens] = useState(null);

  const handleExpand = (lens) => {
    if (expandedLens && expandedLens.id === lens.id) {
      setExpandedLens(null);
    } else {
      setExpandedLens(lens);
    }
  };

  const openPrescriptionHelp = () => {
    Swal.fire({
      html: `
       <div style="display: block; text-align: left;">
        <div><strong>SPH (Sphere)</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Lens strength needed to correct your vision. Negative (-) means nearsighted, Positive (+) means farsighted.</p>
        <div><strong>CYL (Cylinder) & Axis</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">The degree of astigmatism in your eyes, as well as an axis number that indicates the orientation of the astigmatism.</p>
        <div><strong>ADD (Addition)</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">ADD stands for the additional correction that you need for reading. It’s used for multifocal lenses and readers.</p>
        <div><strong>Prism</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Prism is used to correct diplopia (double vision). A prism bends light to align your vision so only a single image is seen.</p>
        <div><strong>Vertical Prism</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Vertical prism correction is for vertical eye misalignments & prescribed upwards in one eye and downwards in the other.</p>
        <div><strong>Horizontal Prism</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Horizontal prism correction is for lateral eye misalignments & prescribed in the same direction for both eyes.</p>
      </div>
      `,
      width: '700px',
      padding: '3em',
      background: '#fff',
      backdrop: `
        rgba(0,0,123,0.4)
        url("/images/nyan-cat.gif")
        left top
        no-repeat
      `
    });
  };

  const formatVND = (priceInVND) => {
    if (!priceInVND && priceInVND !== 0) {
      return "0";
    }
    return priceInVND.toLocaleString('vi-VN');
  };

  return (
    <div className="lens-selection">
      <button
        onClick={() => navigate(`/product/${data.id}`)}
        className="mb-6"
        style={{
          color: '#d39d4e',
          fontSize: '16px',
          verticalAlign: 'middle',
        }}
        // className="text-lg text-gray-600 mb-6 underline"
      >
        &lt; Back to {data.name}
      </button>
      <h2 className="text-2xl font-bold mb-6">Choose your usage</h2>
      <p className="text-lg text-gray-600 mb-6">
      <button className="underline font-bold" onClick={openPrescriptionHelp} style={{ fontSize: '16px', fontWeight: '500', color: '#d39d4e' }}>
      Learn how to read your prescription
    </button>
      </p>
      <div className="space-y-6">
        {lensTypes.map((lens) => {
          const [title, description] = lens.title.split('. ');
          return (
          <div key={lens.id}>
             <div  
             style={{
              borderColor: expandedLens && expandedLens.id === lens.id ? '#d39d4e' : 'transparent',
              backgroundColor: expandedLens && expandedLens.id === lens.id ? '#f6ebdc' : '#f5f5f5',
            }}
            className={`p-6 border rounded-lg cursor-pointer transition ${
              expandedLens && expandedLens.id === lens.id
                ? "border-yellow-500"
                : "border-gray-300 hover:bg-gray-800"
            }`}
            onClick={() => handleExpand(lens)}
          >
          <h3 className="text-xl font-semibold">{title}</h3>
          {description && <p style={{ fontWeight: 400, color: '#6F6F6F' }}>{description}</p>}        </div>
            {expandedLens && expandedLens.id === lens.id &&
              lens.subOptions && (
                <div className="pl-6 mt-4 space-y-4">
                  {lens.subOptions.map((subOption) => (
                   <div
                   key={subOption.id}
                   style={{
                     borderBottom: '1px solid #e4e4e4', // Thêm borderBottom thay vì borderColor
                   }}
                   className={`p-4  cursor-pointer transition ${
                     selectedLens && selectedLens.id === subOption.id
                       ? "border-yellow-500"
                       : "" // Loại bỏ border-gray-300 và backgroundColor
                   }`}
                   onClick={() => {
                     setSelectedLens(subOption);
                     handleLensSelect(subOption);
                   }}
                 >
                       <div className="flex justify-between items-center">
                        <div>
                        <h4
                          className="text-lg font-semibold"
                          style={{
                            display: 'inline-block',
                            padding: '4px 16px',
                            borderRadius: '32px',
                            background: selectedLens && selectedLens.id === subOption.id ? '#f6ebdc' : '#f5f5f5',
                            border: selectedLens && selectedLens.id === subOption.id ? '2px solid #d39d4e' : '2px solid transparent',
                            transition: 'all .2s ease',
                          }}
                        >
                          {subOption.lensName}
                        </h4>
                          <p className="ml-4 mr-4 text-gray-600">
                            {subOption.lensDescription}
                          </p>
                        </div>
                        {typeof subOption.lensPrice !== 'undefined' && (
                          <div className="text-lg font-semibold flex-shrink-0 w-32">
                            {formatVND(subOption.lensPrice)} VND
                          </div>
                        )}
                      </div>

                    </div>
                  ))}
                </div>
              )}
          </div>
        );
        })}
      </div>
    </div>
  );
};

const NonPrescriptionSelection = ({
  selectedLens,
  setSelectedLens,
  handleLensSelect,
  handleAddToCart,
  data,
  navigate,
  setStep,
  formatVND,
}) => {
  const [comment, setComment] = useState('');

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  console.log("Tung", data);
  return (
    <div className="non-prescription-selection">
      <button
        onClick={() => setStep(1)}
        className="mb-6"
        style={{
          color: '#d39d4e',
          fontSize: '16px',
          verticalAlign: 'middle',
        }}
      >
        &lt; Back to Lens Selection
      </button>
      <h2 className="text-2xl font-bold mb-6">Overview your Selection </h2>
      {/* Summary Section */}
      <div className="mb-8 p-6 border border-gray-200 rounded-lg shadow-sm bg-white">
        <h3 className="text-2xl font-bold mb-4 text-gray-800">Order Summary</h3>
        <div className="grid grid-cols-2 gap-4">
          <p className="text-lg font-semibold">Product:</p>
          <p className="text-lg text-gray-600">{data.name}</p>

          <p className="text-lg font-semibold">Price:</p>
          <p className="text-lg text-gray-600">{data ? formatVND(data.price) : 'N/A'} VND</p>

          <p className="text-lg font-semibold">Selected Size:</p>
          <p className="text-lg text-gray-600">{data.selectedSize}</p>

          <p className="text-lg font-semibold">Lens Type:</p>
          <p className="text-lg text-gray-600">{data.lensData.lensType.description.split('.')[0]}</p>

          {selectedLens && (
            <>
              <div className="col-span-2 pt-4 border-t border-gray-200 grid grid-cols-2 gap-4">
                <p className="text-lg font-semibold">Selected Lens:</p>
                <p className="text-lg text-gray-600">{data.lensData.lensName}</p>

                <p className="text-lg font-semibold">Lens Price:</p>
                <p className="text-lg text-gray-600">{data ? formatVND(data.lensData.lensPrice) : 'N/A'} VND</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="space-y-6">
  {/* Replacing the default lens selection with a comment box */}

        <h3 className="text-xl font-semibold">Your Comments</h3>
        <textarea
          className="mt-2 p-4 w-full h-32 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
          placeholder="Leave your comments or notes here..."
          onChange={handleCommentChange} // Corrected to pass the event directly
        ></textarea>
      </div>
      <div className="mt-6 flex justify-end">
        <button
          onClick={handleAddToCart} // Assuming handleAddToCart is defined elsewhere
          className="bg-yellow-500 text-white p-4 mr-2 rounded-lg shadow-md hover:bg-yellow-600 transition"
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

const PrescriptionSelection = ({
  data,
  handleChangeValue,
  // handleSubmit,
  handleAddToCart,
  setStep,
  displayModal,
  setDisplayModal,
  UserInfo,
  navigate,
  togglePopup,
  isPopupVisible,
}) => {
  const openPrescriptionHelp = () => {
    Swal.fire({
      html: `
       <div style="display: block; text-align: left;">
        <div><strong>SPH (Sphere)</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Lens strength needed to correct your vision. Negative (-) means nearsighted, Positive (+) means farsighted.</p>
        <div><strong>CYL (Cylinder) & Axis</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">The degree of astigmatism in your eyes, as well as an axis number that indicates the orientation of the astigmatism.</p>
        <div><strong>ADD (Addition)</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">ADD stands for the additional correction that you need for reading. It’s used for multifocal lenses and readers.</p>
        <div><strong>Prism</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Prism is used to correct diplopia (double vision). A prism bends light to align your vision so only a single image is seen.</p>
        <div><strong>Vertical Prism</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Vertical prism correction is for vertical eye misalignments & prescribed upwards in one eye and downwards in the other.</p>
        <div><strong>Horizontal Prism</strong></div>
        <p style="margin: 0 0 24px; font-size: 16px; line-height: 20px; color: #6f6f6f;">Horizontal prism correction is for lateral eye misalignments & prescribed in the same direction for both eyes.</p>
      </div>
      `,
      width: '700px',
      padding: '3em',
      background: '#fff',
      backdrop: `
        rgba(0,0,123,0.4)
        url("/images/nyan-cat.gif")
        left top
        no-repeat
      `
    });
  };

  return (
    <div className="prescription-selection">
      <button
        onClick={() => setStep(1)}
        className="mb-6"
        style={{
          color: '#d39d4e',
          fontSize: '16px',
          verticalAlign: 'middle',
        }}
      >
        &lt; Back to Lens Selection
      </button>
      <h2 className="text-2xl font-bold mb-6">Enter your prescription</h2>
      <div>
      <p className="text-lg text-gray-600 mb-6">
      <button className="underline font-bold" onClick={openPrescriptionHelp} style={{ fontSize: '16px', fontWeight: '500', color: '#d39d4e' }}>
      Learn how to read your prescription
    </button>
      </p>
    </div>
    <div style={{ backgroundColor: '#f5f5f5', borderRadius: '8px', zIndex: 10, padding: '18px 32px 18px 0px'}}>
        <div className="space-y-6 mb-5">
          <div className="prescription-row grid grid-cols-4 gap-6 items-center">
             <span className="col-span-1" style={{ width: '50%' }}></span>
            <div className="col-span-1 text-lg text-center" style={{ fontWeight: '500', lineHeight: '1.3'}}>SPH</div>
            <div className="col-span-1 text-lg text-center" style={{ fontWeight: '500', lineHeight: '1.3'}}>SYL</div>
            <div className="col-span-1 text-lg text-center" style={{ fontWeight: '500', lineHeight: '1.3'}}>AXIS</div>
          </div>
      </div>
        <div className="space-y-6">
          <PrescriptionInput
            label="OD"
            subLabel="right eye"
            handleChangeValue={handleChangeValue}
            fields={["odSphere", "odCylinder", "odAxis"]}
          />
          <PrescriptionInput
            label="OS"
            subLabel="left eye"
            handleChangeValue={handleChangeValue}
            fields={["osSphere", "osCylinder", "osAxis"]}
          />
          <PrescriptionInput
            label="ADD"
            subLabel="left/right eye"
            handleChangeValue={handleChangeValue}
            fields={["addOD", "addOS"]}
          />
          <PrescriptionInput
            label="PD"
            handleChangeValue={handleChangeValue}
            fields={["pdType"]}
          />
          {/* <div>
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your address
            </label>
            <input
              onChange={(e) => handleChangeValue(e, "address")}
              type="text"
              name="address"
              id="address"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div> */}
        </div>
      </div>
      <div className="mt-6 flex justify-end">

        {UserInfo ? (

          <button
            onClick={handleAddToCart}
            className="bg-yellow-500 text-white p-4 mr-2 rounded-lg shadow-md hover:bg-yellow-600 transition"
          >
            Add to Cart
          </button>
        ) : (
          <button

            onClick={handleAddToCart}
            className="bg-yellow-500 text-white p-4 mr-2 rounded-lg shadow-md hover:bg-yellow-600 transition"
          >
            Log in to Add to Cart
          </button>
        )}

        {/* <button
          onClick={() => handleSubmit()}
          className="bg-yellow-500 text-white p-4 rounded-lg shadow-md hover:bg-yellow-600 transition"
        >
          Save & Continue
        </button> */}
        {/* <LoginModal
          toggle={displayModal}
          setDisplayModal={setDisplayModal}
          data={data}
        /> */}
      </div>
    </div>
  );
};

const PrescriptionInput = ({ label, subLabel, handleChangeValue, fields }) => {
  return (
    <div className="prescription-row grid grid-cols-4 gap-6 items-center">
      <div className="col-span-1 text-right">
        <span className="text-lg font-bold">{label}</span>
        {subLabel && (
          <>
            <br />
            <span className="text-sm" style={{ fontWeight: '500', color: '#6f6f6f', lineHeight: '1' }}>{subLabel}</span>
          </>
        )}
      </div>
      {fields.map((field) => (
        <input
          key={field}
          type="number"
          onChange={(e) => handleChangeValue(e, field)}
          className="border border-gray-300 p-4 rounded col-span-1"
          placeholder="0.00"
        />
      ))}
    </div>
  );
};

export default SelectLenses;
