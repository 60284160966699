import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";

// IMPORT API SERVICES
import { useDashboardService } from "../../services/index";

// Custom styles for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflow: "auto",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const Userpage = () => {
  // State variables
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: 0,
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    status: true,
    roleID: 0,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [formValid, setFormValid] = useState(true); // State to track form validation
  const [emailValid, setEmailValid] = useState(true); // State to track email validity
  const [passwordMatch, setPasswordMatch] = useState(true); // State to track password match
  const [currentPage, setCurrentPage] = useState(1); // State to track current page
  const [itemsPerPage] = useState(10); // State to track items per page

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [accountData] = await Promise.all([getAllAccount()]);

        if (accountData !== null && accountData.data.length > 0) {
          setData(accountData.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError("No data found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if updating password or confirmPassword
    if (name === "password" || name === "confirmPassword") {
      setFormData({
        ...formData,
        [name]: value,
      });

      // Check if passwords match
      if (name === "confirmPassword") {
        setPasswordMatch(formData.password === value);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleViewEdit = (item) => {
    setFormData({
      id: item.id,
      username: item.username,
      password: item.password,
      confirmPassword: item.password, // Assuming confirm password defaults to password
      email: item.email,
      status: item.status,
      roleID: item.roleID,
    });
    openModal();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      console.log("Updating user with data:", formData); // Log dữ liệu khi cập nhật
      const response = await fetch(`http://visionup.somee.com/api/Account`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: formData.id,
          username: formData.username,
          password: formData.password,
          email: formData.email,
          status: formData.status,
          roleID: parseInt(formData.roleID),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update user.");
      }

      const updatedUser = await response.json();
      console.log("Updated user data:", updatedUser); // Log dữ liệu đã cập nhật
      setData((prevData) =>
        prevData.map((item) => (item.id === updatedUser.id ? updatedUser : item))
      );

      toast.success("User updated successfully.");
      closeModal();
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user.");
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      console.log("Adding user with data:", formData); // Log dữ liệu khi thêm
      const response = await fetch("http://visionup.somee.com/api/Account", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.username,
          password: formData.password,
          email: formData.email,
          status: formData.status,
          roleID: parseInt(formData.roleID),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add user.");
      }

      const newUser = await response.json();
      console.log("New user data:", newUser); // Log dữ liệu người dùng mới
      setData((prevData) => [...prevData, newUser]);

      toast.success("User added successfully.");
      closeModal();
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Failed to add user.");
    }
  };

  const handleDelete = (userId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              console.log("Deleting user with ID:", userId); // Log ID của người dùng bị xóa
              const response = await fetch(
                `http://visionup.somee.com/api/Account/${userId}`,
                {
                  method: "DELETE",
                }
              );

              if (!response.ok) {
                throw new Error("Failed to delete user.");
              }

              setData((prevData) => prevData.filter((item) => item.id !== userId));
              toast.success("User deleted successfully.");
            } catch (error) {
              console.error("Error deleting user:", error);
              toast.error("Failed to delete user.");
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter((item) =>
    item.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the data to display based on current page and items per page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination controls
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFormData({
      id: 0,
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      status: true,
      roleID: 0,
    });
    setFormValid(true); // Reset form validation state
    setEmailValid(true); // Reset email validation state
    setPasswordMatch(true); // Reset password match state
  };

  const { getAllAccount, updateUser, addUser, deleteUser } = useDashboardService();

  // Functions to handle pagination
  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Users Management</h1>
      <div className="flex mb-4 items-center">
        <button
          className="bg-green-500 text-white px-4 py-2 rounded mr-2"
          onClick={openModal}
        >
          Add User
        </button>
        <input
          type="text"
          placeholder="Search users by username..."
          value={searchTerm}
          onChange={handleSearchTermChange}
          className="border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>
      {!formValid && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Form is not valid!</strong>
        </div>
      )}
      {loading ? (
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className="overflow-x-auto mb-4">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">ID</th>
                <th className="py-2 px-4 border-b text-left">Username</th>
                <th className="py-2 px-4 border-b text-left">Email</th>
                <th className="py-2 px-4 border-b text-left">Status</th>
                <th className="py-2 px-4 border-b text-left">Role</th>
                <th className="py-2 px-4 border-b text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item) => (
                <tr key={item.id}>
                  <td className="py-2 px-4 border-b text-left">{item.id}</td>
                  <td className="py-2 px-4 border-b text-left">{item.username}</td>
                  <td className="py-2 px-4 border-b text-left">{item.email}</td>
                  <td className="py-2 px-4 border-b text-left">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${item.status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                      {item.status ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-left">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${item.role.name.trim().toLowerCase() === 'doctor' ? 'bg-yellow-100 text-yellow-800' :
                        item.role.name.trim().toLowerCase() === 'admin' ? 'bg-red-100 text-red-800' :
                          'bg-blue-100 text-blue-800'
                      }`}>
                      {item.role.name}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-left">
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                      onClick={() => handleViewEdit(item)}
                    >
                      View/Edit
                    </button>
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-center mt-4">
        <button
          onClick={goToPrevPage}
          className={`px-4 py-2 mx-1 ${currentPage === 1 ? "bg-gray-300 text-white cursor-not-allowed" : "bg-white border"
            }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`px-4 py-2 mx-1 ${currentPage === number ? "bg-blue-500 text-white" : "bg-white border"
              }`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={goToNextPage}
          className={`px-4 py-2 mx-1 ${currentPage === Math.ceil(filteredData.length / itemsPerPage) ? "bg-gray-300 text-white cursor-not-allowed" : "bg-white border"
            }`}
          disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
        >
          Next
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="User Modal"
        style={customStyles}
      >
        <h2 className="text-xl font-bold mb-4">
          {formData.id ? "Edit User" : "Add User"}
        </h2>
        <form onSubmit={formData.id ? handleUpdate : handleAdd}>
          <div className="mb-4">
            <label className="block mb-2">Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`p-2 border ${emailValid ? "border-gray-300" : "border-red-500"
                } rounded-md w-full`}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              title="Please enter a valid email address"
            />
            {!emailValid && (
              <p className="text-red-500 text-sm mt-1">Invalid email address.</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              className={`p-2 border ${passwordMatch ? "border-gray-300" : "border-red-500"
                } rounded-md w-full`}
              required
            />
            {!passwordMatch && (
              <p className="text-red-500 text-sm mt-1">Passwords do not match.</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2">Status</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded-md w-full"
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Role</label>
            <select
              name="roleID"
              value={formData.roleID}
              onChange={handleInputChange}
              className="border border-gray-300 p-2 rounded w-full"
            >
              <option value="0">Select Role</option>
              <option value="1" color="green">User</option>
              <option value="2" color="yellow">Admin</option>

            </select>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {formData.id ? "Update User" : "Add User"}
            </button>
            <button
              type="button"
              className="bg-gray-500 text-white px-4 py-2 rounded ml-2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default Userpage;

