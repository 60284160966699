import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Tag, Button } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CreateProfileModal from "./CreateProfileModal"; // Import your CreateProfileModal component

const { Column } = Table;

const ProfileList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [userInfo, setUserInfo] = useState(null);
  const [createModalVisible, setCreateModalVisible] = useState(false); // State to control visibility of CreateProfileModal

  const navigateTo = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://visionup.azurewebsites.net/api/Profile?AccountID=${userInfo.id}&PageIndex=${currentPage}&PageSize=${pageSize}`
      );
      const responseData = response.data;
      setData(responseData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userInfoFromStorage = JSON.parse(localStorage.getItem("UserInfo"));
    if (!userInfoFromStorage) {
      navigateTo("/login");
      return;
    }
    setUserInfo(userInfoFromStorage);
  }, [navigateTo]);

  useEffect(() => {
    if (!userInfo) return;
    fetchData();
  }, [userInfo, currentPage, pageSize]);

  const handleRowClick = (record) => {
    setSelectedRow(record.id);
    navigateTo(`/ProfileMeasurement/${record.id}`);
  };

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination);
    setPageSize(filters);
  };

  const handleCreateModalOpen = () => {
    setCreateModalVisible(true);
  };

  const handleCreateModalClose = () => {
    setCreateModalVisible(false);
  };

  const handleCreateProfile = (newProfile) => {
    fetchData();
    handleCreateModalClose();
  };

  return (
    <>
      <h1>Profile</h1>
      <Button type="default" onClick={handleCreateModalOpen} style={{ float: 'right', marginRight: '20px' }}>
        Create Profile
      </Button>
      <Table
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          onChange: handleTableChange,
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          className:
            record.id === selectedRow ? "table-row-selected" : "table-row-hover",
        })}
      >
        <Column title="ID" dataIndex="id" key="id" />
        <Column title="Full Name" dataIndex="fullName" key="fullName" />
        <Column title="Phone" dataIndex="phone" key="phone" />
        <Column title="Address" dataIndex="address" key="address" />
        <Column
          title="Birthday"
          dataIndex="birthday"
          key="birthday"
          render={(birthday) => moment(birthday).format("DD-MM-YYYY")}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status) => (
            <Tag color={status ? "green" : "volcano"}>
              {status ? "Active" : "Inactive"}
            </Tag>
          )}
        />
      </Table>

      <CreateProfileModal
        visible={createModalVisible}
        onCancel={handleCreateModalClose}
        onCreate={handleCreateProfile}
        UserInfo={userInfo} // Pass userInfo to the modal
      />
    </>
  );
};

export default ProfileList;
