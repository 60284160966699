import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDashboardService } from "../../services/index";
import { useEyeGlassService } from "../../services/index";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";




const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflow: "auto",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};



const Orders = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [formData, setFormData] = useState({
    accountID: 0,
    status: true,
    total: 0,
    voucherID: 0,
    receiverAddress: "",
  });
  const [updateFormData, setUpdateFormData] = useState({
    id: 0,
    status: true,
    senderAddress: "",
    receiverAddress: "",
    code: "",
    process: 0,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const { getAllAccount, getAllOrderPageSize1000 } = useDashboardService();
  const { postMail, fetchAccountById, fetchOrderById } = useEyeGlassService();


  // Define the process status
  const ProcessStatus = {
    Pending: 0,
    Processing: 1,
    Shipping: 2,
    Delivered: 3,
    Completed: 4,
    Canceled: 5,
  };

  // Define the process status options
  const processStatusOptions = Object.entries(ProcessStatus).map(([key, value]) => ({
    label: key,
    value: value,
  }));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination controls
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const goToNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  const goToPrevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  const setPage = (pageNumber) => setCurrentPage(pageNumber);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersData, accountsData] = await Promise.all([
          getAllOrderPageSize1000(),
          getAllAccount(),
        ]);

        setData(ordersData.data);
        setAccountData(accountsData.data);
        mappingData(ordersData.data, accountsData.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const results = tableData.filter((item) =>
      item.accountName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(results);
  }, [searchTerm, tableData]);



  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    // Check if the input name is 'process' and parse the value to an integer.
    // For other fields, use the value as is.
    const updatedValue = name === 'process' ? parseInt(value, 10) : value;
    setUpdateFormData({
      ...updateFormData,
      [name]: updatedValue,
    });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://visionup.azurewebsites.net/api/Order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to add order.");
      }

      const newOrder = await response.json();
      setData([...data, newOrder]);
      toast.success("Order added successfully.");
      closeAddModal();
    } catch (error) {
      console.error("Error adding order:", error);
      toast.error("Failed to add order.");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      console.log("Tung", updateFormData);
      const response = await fetch(
        `https://visionup.azurewebsites.net/api/Order`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateFormData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update order.");
      }

      const updatedOrder = await response.json();
      const updatedData = data.map((item) =>
        item.id === updatedOrder.id ? { ...item, ...updatedOrder } : item
      );
      setData(updatedData);
      toast.success("Order updated successfully.");

      // Define a mapping from process integers to process names
      const processNames = {
        0: "Pending",
        1: "Processing",
        2: "Shipping",
        3: "Delivered",
        4: "Completed",
        5: "Canceled",
        // Add more mappings as necessary
      };

      // Fetch account details
      const accountDetails = await fetchAccountById(updatedOrder.accountID);
      if (accountDetails) {
        // Use the process value from updatedOrder to get the process name
        const processName = processNames[updatedOrder.process] || "Unknown Process";


        const formattedOrderDate = new Date(updateFormData.orderDate).toLocaleDateString("en-US", {
          weekday: 'long', // "Wednesday"
          year: 'numeric', // "2024"
          month: 'long', // "June"
          day: 'numeric' // "27"
        });

        const mailContent = {
          to: accountDetails.email ? accountDetails.email : accountDetails.username,
          subject: "Order Update Notification",
          body: `<p>${accountDetails.username}, your order has been successfully updated. Current status: ${processName}.` +
                `<br><b>Order Code:</b> ${updateFormData.code}` +
                `<br><b>Order Date:</b> ${formattedOrderDate}` +
                `<br>For more details, you can go to <a href="https://visionup.id.vn/order">Go to Your Order</a></p>`
        };
        // Send notification mail
        await postMail(mailContent);
        toast.success("Notification sent to user.");
      } else {
        toast.error("Failed to fetch account details.");
      }

      closeUpdateModal();
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Failed to update order.");
    }
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this order?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await fetch(
                `https://visionup.azurewebsites.net/api/Order/${id}`,
                {
                  method: "DELETE",
                }
              );

              if (!response.ok) {
                throw new Error("Failed to delete order.");
              }

              setData(data.filter((order) => order.id !== id));
              toast.success("Order deleted successfully.");
            } catch (error) {
              console.error("Error deleting order:", error);
              toast.error("Failed to delete order.");
            }
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleView = (order) => {
    setUpdateFormData(order);
    setUpdateModalIsOpen(true);
  };

  const mappingData = (orders, accounts) => {
    let mappedData = [];
    orders.forEach((order) => {
      const account = accounts.find((acc) => acc.id === order.accountID);
      if (account) {
        mappedData.push({
          id: order.id,
          accountName: account.profiles[0]?.fullname || account.username,
          accountID: order.accountID,
          orderDate: order.orderDate,
          status: order.status,
          senderAddress: order.senderAddress,
          receiverAddress: order.receiverAddress,
          code: order.code,
          process: order.process,
        });
      }
    });
    mappedData.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
    setTableData(mappedData);
    setFilteredData(mappedData);
  };

  const convertDateToString = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setFormData({
      accountID: 0,
      status: true,
      total: 0,
      voucherID: 0,
      receiverAddress: "",
    });
  };

  const openUpdateModal = () => {
    setUpdateModalIsOpen(true);
  };

  const closeUpdateModal = () => {
    setUpdateModalIsOpen(false);
    setUpdateFormData({
      id: 0,
      status: true,
      senderAddress: "",
      receiverAddress: "",
      code: "",
      process: 0,
    });
  };

  // Calculate indexes for pagination



  // Pagination controls
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Orders Management</h1>
      <div className="flex items-center mb-4">
        <button
          onClick={openAddModal}
          className="bg-green-500 text-white px-4 py-2 rounded mr-2"
        >
          Add Order
        </button>
        <input
          type="text"
          placeholder="Search by customer name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      {/* Add Order Modal */}
      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add Order Modal"
      >
        <h2 className="text-2xl font-bold mb-4">Add Order</h2>
        <form onSubmit={handleAdd}>
          <div className="mb-4">
            <label
              htmlFor="accountID"
              className="block text-sm font-medium text-gray-700"
            >
              Account ID
            </label>
            <input
              type="text"
              id="accountID"
              name="accountID"
              value={formData.accountID}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              value={formData.status ? "true" : "false"}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="total"
              className="block text-sm font-medium text-gray-700"
            >
              Total
            </label>
            <input
              type="number"
              id="total"
              name="total"
              value={formData.total}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="voucherID"
              className="block text-sm font-medium text-gray-700"
            >
              Voucher ID
            </label>
            <input
              type="number"
              id="voucherID"
              name="voucherID"
              value={formData.voucherID}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="receiverAddress"
              className="block text-sm font-medium text-gray-700"
            >
              Receiver Address
            </label>
            <input
              type="text"
              id="receiverAddress"
              name="receiverAddress"
              value={formData.receiverAddress}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeAddModal}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
            >
              Cancel
            </button>
            <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
              Add
            </button>
          </div>
        </form>
      </Modal>

      {/* Update Order Modal */}
      <Modal
        isOpen={updateModalIsOpen}
        onRequestClose={closeUpdateModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Update Order Modal"
      >
        <h2 className="text-2xl font-bold mb-4">Update Order</h2>
        <form onSubmit={handleUpdate}>
          <div className="mb-4">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              value={updateFormData.status ? "true" : "false"}
              onChange={handleUpdateInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="senderAddress"
              className="block text-sm font-medium text-gray-700"
            >
              Sender Address
            </label>
            <input
              type="text"
              id="senderAddress"
              name="senderAddress"
              value={updateFormData.senderAddress}
              onChange={handleUpdateInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="receiverAddress"
              className="block text-sm font-medium text-gray-700"
            >
              Receiver Address
            </label>
            <input
              type="text"
              id="receiverAddress"
              name="receiverAddress"
              value={updateFormData.receiverAddress}
              onChange={handleUpdateInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="code"
              className="block text-sm font-medium text-gray-700"
            >
              Code
            </label>
            <input
              type="text"
              id="code"
              name="code"
              value={updateFormData.code}
              onChange={handleUpdateInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="process" className="block text-sm font-medium text-gray-700">
              Process
            </label>
            <select
              id="process"
              name="process"
              value={updateFormData.process}
              onChange={handleUpdateInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              {processStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeUpdateModal}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Update
            </button>
          </div>
        </form>
      </Modal>

      <table className="w-full px-2 bg-white shadow rounded-lg mt-4">
        <thead>
          <tr>
            <th style={{ maxWidth: "50px", wordWrap: "break-word" }} className="py-2 text-center">Order ID</th>
            <th style={{ maxWidth: "100px", wordWrap: "break-word" }} className="py-2 text-center">Customer</th>
            <th style={{ maxWidth: "70px", wordWrap: "break-word" }} className="py-2 text-center">Date</th>
            <th style={{ maxWidth: "100px", wordWrap: "break-word" }} className="py-2 text-center">Code</th>
            <th style={{ maxWidth: "120px", wordWrap: "break-word" }} className="py-2 text-center">Sender Address</th>
            <th style={{ maxWidth: "120px", wordWrap: "break-word" }} className="py-2 text-center">Receiver Address</th>
            <th style={{ maxWidth: "50px", wordWrap: "break-word" }} className="py-2 text-center">Status</th>
            <th style={{ maxWidth: "150px", wordWrap: "break-word" }} className="py-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr key={item.id}>
              <td style={{ maxWidth: "50px", wordWrap: "break-word" }} className="py-2 text-center">{index + 1}</td>
              <td style={{ maxWidth: "100px", wordWrap: "break-word" }} className="py-2 text-center">{item.accountName}</td>
              <td style={{ maxWidth: "70px", wordWrap: "break-word" }} className="py-2 text-center">{convertDateToString(item.orderDate)}</td>
              <td style={{ maxWidth: "120px", wordWrap: "break-word" }} className="py-2 text-center">{item.code}</td>
              <td style={{ maxWidth: "120px", wordWrap: "break-word" }} className="py-2 text-center">{item.senderAddress}</td>
              <td style={{ maxWidth: "120px", wordWrap: "break-word" }} className="py-2 text-center">{item.receiverAddress}</td>
              <td style={{
                maxWidth: "10px",
                wordWrap: "break-word",
                color: item.status ? "green" : "red",
                backgroundColor: item.status ? "#e0f8ec" : "#f8e0e0"
              }} className="py-2 text-center">
                {item.status ? "Success" : "Fail"}
              </td>              <td style={{ maxWidth: "150px", wordWrap: "break-word" }} className="py-2 text-center">
                <button
                  onClick={() => handleView(item)}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  View/Edit
                </button>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded ml-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={goToPrevPage}
          className={`px-2 py-1 mx-1 ${currentPage === 1 ? "bg-gray-300 text-white cursor-not-allowed" : "bg-white border"}`}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
          <button
            key={number}
            onClick={() => setPage(number)}
            className={`px-2 py-1 mx-1 ${currentPage === number ? "bg-blue-500 text-white" : "bg-white border"}`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={goToNextPage}
          className={`px-2 py-1 mx-1 ${currentPage === totalPages ? "bg-gray-300 text-white cursor-not-allowed" : "bg-white border"}`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Orders;