import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Switch from '@mui/material/Switch';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

import { useLensService } from "../../services/index";
import { useLensImageService } from "../../services/index";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "1200px",
    maxHeight: "80vh",
    overflow: "auto",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const LensesPage = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [lensTypes, setLensTypes] = useState([]);
  const [eyeReflactives, setEyeReflactives] = useState([]);

  // Pagination variables
  const [count, setCount] = useState(0); // Add a count state to store the total number of eyeglasses
  const [pageIndex, setpageIndex] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(count / pageSize);

  // Search term state
  const [searchTerm, setSearchTerm] = useState("");


  // Behavior variables
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Modal state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    id: 0,
    lensName: "",
    lensDescription: "",
    lensPrice: 0,
    quantity: 0,
    status: true,
    lensTypeID: 0,
    eyeReflactiveID: 0,
  });


  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eyeGlassId = 'your_eye_glass_id'; // Replace with your actual eye glass ID
        const angles = ['Front', 'Back', 'LeftSide', 'RightSide', 'Top', 'Bottom', 'Folded', 'Unfolded'];

        const imagePromises = angles.map(async (angle) => {
          const image = await fetchImageByEyeGlassId(eyeGlassId, angle);
          return { angle, image };
        });

        const fetchedImages = await Promise.all(imagePromises);
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchData();
  }, []);

  const [imageFiles, setImageFiles] = useState({
    Front: null,
    Back: null,
    LeftSide: null,
    RightSide: null,
    Top: null,
    Bottom: null,
    Folded: null,
    Unfolded: null,
  });

  const AngleView = ["Front", "Back", "LeftSide", "RightSide", "Top", "Bottom", "Folded", "Unfolded"];

  const handleImageChange = (angle, file) => {
    setImageFiles(prevFiles => ({
      ...prevFiles,
      [angle]: file
    }));
  };


  // Validation error state
  const [validationError, setValidationError] = useState("");

  const { createLens, validateInput, deleteLens, fetchLensById, fetchLensType, fetchEyeReflactive, getAllLens, updateLens, countLens } = useLensService();
  const { deleteImage, fetchImageByEyeGlassId, postImage, putImage } = useLensImageService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Define search and pagination parameters
        const params = {
          // searchName: searchTerm, // Use the searchTerm state for searching by name
          pageSize: 10,
          pageIndex: pageIndex, // Example page number // Example page size
        };

        const lensCount = await countLens(false);
        setCount(lensCount);

        const lensData = await getAllLens(params);


        if (lensData !== null && lensData.data.length > 0) {
          setData(lensData.data);
          setTableData(lensData.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError('No data found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, [pageSize, pageIndex]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const lensTypesData = await fetchLensType();
      if (lensTypesData) {
        setLensTypes(lensTypesData);
      }

      const eyeReflactivesData = await fetchEyeReflactive();
      if (eyeReflactivesData) {
        setEyeReflactives(eyeReflactivesData);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (modalIsOpen && formData.id) {
      const fetchImages = async () => {
        try {
          const images = await fetchImageByEyeGlassId(formData.id);
          console.log("Tung ", images);
          const updatedImageFiles = {};
          AngleView.forEach(angle => {
            const foundImage = images.find(img => img.angleView === angle);
            updatedImageFiles[angle] = foundImage ? URL.createObjectURL(foundImage.file) : null;
          });
          setImageFiles(updatedImageFiles);
        } catch (error) {
          console.error("Failed to fetch images", error);
        }
      };

      fetchImages();
    }
  }, [modalIsOpen, formData.id]);

  useEffect(() => {
    AngleView.forEach(angle => {
      console.log(imageFiles[angle]);
    });
  }, [imageFiles]);

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const validationErrors = validateInput(formData);
      if (Object.keys(validationErrors).length > 0) { // Check if there are any validation errors
        setValidationError(validationErrors);
        // Display the first validation error message
        toast.error(Object.values(validationErrors)[0]);
        return;
      }
      console.log(formData);
      const newLens = await createLens(formData);
      console.log(newLens);

      if (newLens) {
        await getAllLens(); // Assume this function fetches the latest data and updates state
        toast.success("Lens added successfully.");
        closeModal();
      } else {
        throw new Error("Failed to add lens.");
      }
    } catch (error) {
      console.error("Failed to add lens:", error);
      toast.error("Failed to add lens.");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);
      const validationErrors = validateInput(formData);
      if (Object.keys(validationErrors).length > 0) { // Check if there are any validation errors
        setValidationError(validationErrors);
        toast.error(Object.values(validationErrors)[0]);
        return;
      }

      const updatedLens = await updateLens(formData);

      if (updatedLens) {
        await getAllLens(); // Assume this function fetches the latest data and updates state
        toast.success("Lens updated successfully.");
        closeModal();
      } else {
        throw new Error("Failed to update lens.");
      }
    } catch (error) {
      console.error("Error updating lens:", error);
      toast.error("Failed to update lens.");
    }
  };
  // Make sure to define fetchTableData function that updates your table data state with the latest data from the server.

  const handleViewEdit = async (item) => {
    try {
      const lensDetails = await fetchLensById(item.id); // Assuming fetchLensById is the method to fetch lens details by ID
      if (lensDetails) {
        setFormData({
          id: lensDetails.id,
          lensName: lensDetails.lensName,
          lensDescription: lensDetails.lensDescription,
          lensPrice: lensDetails.lensPrice,
          quantity: lensDetails.quantity,
          status: lensDetails.status,
          lensTypeID: lensDetails.lensTypeID,
          eyeReflactiveID: lensDetails.eyeReflactiveID,
        });
        setIsEditing(true);
        openModal();
      } else {
        console.log('No lens details found for ID:', item.id);
      }
    } catch (error) {
      console.error('Error fetching lens details:', error);
    }
  };

  const handleDelete = (lensId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this lens?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              console.log(lensId);
              await deleteLens(lensId); // Use deleteLens function with the lens ID
              // Fetch the updated lens data after successful deletion
              const lensData = await getAllLens(); // Assuming fetchAllLenses is the method to fetch all lens data
              if (lensData !== null && lensData.length > 0) {
                setData(lensData); // Update the state with the fetched data
                setTableData(lensData); // Update the table data with the fetched data
              } else {
                setData([]); // Set data to an empty array if no data is found
                setTableData([]); // Set table data to an empty array if no data is found
                setError('No data found'); // Set an error message
              }
              toast.success("Lens deleted successfully."); // Show success message
            } catch (error) {
              console.error("Error:", error); // Log the error
              toast.error("Failed to delete lens."); // Show error message
            }
          },
        },
        {
          label: "No",
          onClick: () => { }, // Do nothing on "No" click
        },
      ],
    });
  };


  const Pagination = ({ pageIndex, totalPages, onPageChange, totalRecords }) => {
    // Ensure pageIndex, totalPages, and totalRecords are treated as numbers and provide default values if they are not valid
    const currentPageIndex = Number(pageIndex) || 1;
    const currentTotalPages = Number(totalPages) || 1;
    const currentTotalRecords = Number(totalRecords) || 0;

    // Generate page index buttons
    const pageIndexButtons = Array.from({ length: currentTotalPages }, (_, i) => i + 1).map(pageNum => (
      <button
        key={pageNum}
        disabled={currentPageIndex === pageNum}
        onClick={() => onPageChange(pageNum)}
        className={`px-2 py-1 text-gray-800 rounded ${currentPageIndex === pageNum ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
        aria-label={`Go to page ${pageNum}`}
      >
        {pageNum}
      </button>
    ));

    // Calculate the range of records being shown
    const startRecord = (currentPageIndex - 1) * 10 + 1;
    const endRecord = Math.min(currentPageIndex * 10, currentTotalRecords);

    return (
      <div className="flex justify-between items-center w-full">
        <span className="text-gray-700">{`Showing ${startRecord} to ${endRecord} of ${currentTotalRecords} Records`}</span>
        <div className="flex justify-center items-center space-x-2">
          <button
            disabled={currentPageIndex <= 1}
            onClick={() => onPageChange(currentPageIndex - 1)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
            aria-label="Previous page"
          >
            Prev
          </button>
          {pageIndexButtons}
          <button
            disabled={currentPageIndex >= currentTotalPages}
            onClick={() => onPageChange(currentPageIndex + 1)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
            aria-label="Next page"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const handlePageChange = (newPage) => {
    setpageIndex(newPage);
  };
  const filteredTableData = tableData.filter((item) =>
    item.lensName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFormData({
      id: 0,
      lensName: "",
      lensDescription: "",
      lensPrice: 0,
      quantity: 0,
      status: true,
      lensTypeID: 0,
      eyeReflactiveID: 0,
    });
    setValidationError("");
    setIsEditing(false);
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    console.log(name, value);
    let updatedValue = value;

    // Convert numeric fields to numbers
    const numericFields = ["lensPrice", "quantity", "lensTypeID", "eyeReflactiveID"];
    if (numericFields.includes(name)) {
      updatedValue = value === "" ? "" : Number(value);
    }

    if (name === "status") {
      if (typeof setFormData === "function") { // Kiểm tra setFormData là một hàm
        setFormData(prevFormData => ({
          ...prevFormData,
          status: !prevFormData.status // Toggle the status
        }));
      } else {
        console.error("setFormData is not defined or not a function");
      }
      return; // Early return to prevent further execution
    }


    // Update the form data state
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: updatedValue
    }));
  }




  return (
    <div>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Lenses Management</h1>
        <div className="flex justify-between mb-4">
          <input
            type="text"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-300 p-2 rounded"
          />
          <button
            onClick={openModal}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Add
          </button>
        </div>
        <TableContainer component={Paper} elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Lens ID</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Name</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Description</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Price</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Quantity</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Type</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Refractive</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242', px: 2 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTableData.map((item) => (
                <TableRow key={item.id} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" sx={{ px: 2 }}>{item.id}</TableCell>
                  <TableCell align="center" sx={{ px: 2 }}>{item.lensName}</TableCell>
                  <TableCell align="center" sx={{ px: 2 }}>{item.lensDescription}</TableCell>
                  <TableCell align="center" sx={{ px: 2 }}>${item.lensPrice}</TableCell>
                  <TableCell align="center" sx={{ px: 2 }}>{item.quantity}</TableCell>
                  <TableCell align="center" sx={{ px: 2 }}>{item.lensType.description}</TableCell>
                  <TableCell align="center" sx={{ px: 2 }}>{item.eyeReflactive.reflactiveName}</TableCell>
                  <TableCell align="center" sx={{ px: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ marginRight: '8px' }}
                      onClick={() => handleViewEdit(item)}
                    >
                      Detail
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="mt-4">
          <Pagination
            pageIndex={pageIndex}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalRecords={count}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Lens Modal"
      >
        <h2 className="text-2xl font-bold mb-4">
          {isEditing ? "Edit Lens" : "Add Lens"}
        </h2>
        <div className="flex">
          <div className="flex-1 mr-4">
            <form onSubmit={isEditing ? handleUpdate : handleAdd}>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Lens Name</label>
                <input
                  type="text"
                  className="border border-gray-300 p-2 rounded w-full"
                  name="lensName"
                  value={formData.lensName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Lens Type ID</label>
                <select
                  className="border border-gray-300 p-2 rounded w-full"
                  name="lensTypeID"
                  value={formData.lensTypeID}
                  onChange={handleInputChange}
                  required
                >
                  {lensTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Eye Reflactive ID</label>
                <select
                  className="border border-gray-300 p-2 rounded w-full"
                  name="eyeReflactiveID"
                  value={formData.eyeReflactiveID}
                  onChange={handleInputChange}
                  required
                >
                  {eyeReflactives.map((reflactive) => (
                    <option key={reflactive.id} value={reflactive.id}>
                      {reflactive.reflactiveName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-8 flex justify-between">
                <div className="mb-3 w-1/2 pr-2">
                  <label className="block text-sm font-bold mb-2">Lens Price</label>
                  <input
                    type="number"
                    className="border border-gray-300 p-2 rounded w-full"
                    name="lensPrice"
                    value={formData.lensPrice}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3 w-1/2 pl-2">
                  <label className="block text-sm font-bold mb-2">Quantity</label>
                  <input
                    type="number"
                    className="border border-gray-300 p-2 rounded w-full"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Lens Description</label>
                <input
                  type="text"
                  className="border border-gray-300 p-2 rounded w-full"
                  name="lensDescription"
                  value={formData.lensDescription}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Status</label>
                <Switch
                  checked={formData.status}
                  onChange={e => {
                    if (e.target && typeof e.target.checked === "boolean") { // Kiểm tra e.target và e.target.checked
                      handleInputChange({ target: { name: 'status', value: e.target.checked } });
                    } else {
                      console.error("e.target or e.target.checked is not defined");
                    }
                  }}
                  name="status"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {isEditing ? "Update" : "Add"}
                </button>
              </div>
            </form>
          </div>
          <div className="flex-1 mt-7 ml-4">
            {/* <div className="grid grid-cols-2 gap-4">
              {Object.keys(imageFiles).map((angle) => (
                <div key={angle}>
                  <label className="block text-sm font-bold mb-2">{angle} Image</label>
                  <input
                    type="file"
                    onChange={(e) => handleImageChange(angle, e.target.files[0])}
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                  {imageFiles[angle] && (
                    <img
                      src={URL.createObjectURL(imageFiles[angle])}
                      alt={`${angle} preview`}
                      className="mt-2 max-h-40 w-auto"
                    />
                  )}
                </div>
              ))}
            </div> */}

            <div>
              <h1>Demo Image Modal</h1>
              {images.map((imageData) => (
                <div key={imageData.angleView}
                  className="border-dashed border-2 border-gray-300 h-48 flex justify-center items-center cursor-pointer">
                  <h2>{imageData.angleView}</h2>
                  <img src={imageData.url} alt={imageData.angleView} />
                </div>
              ))}
            </div>
          </div>

        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default LensesPage;