import { useEffect, useState } from "react";
import { Table, Tag, Button } from "antd";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

const { Column } = Table;

const ExamResultItem = () => {
    const { id } = useParams(); // Get the examResultId from the URL
    const [examResultData, setExamResultData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchExamResultData = async () => {
            try {
                const response = await axios.get(`https://visionup.azurewebsites.net/api/ExamResultItem/examResult/${id}`);
                const formattedData = response.data.map((item, index) => ({
                    ...item,
                    examDate: moment(item.examDate).format("DD-MM-YYYY"),
                    questionNumber: index + 1 // Add 1 to start index from 1 instead of 0
                }));
                setExamResultData(formattedData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchExamResultData();
    }, [id]); // Ensure the effect runs when id changes

    const navigate = useNavigate();

    const handleRowClick = (record) => {
        navigate(`/ExamResult/${record.examResultId}`);
    };

    const renderActionColumn = (text, record) => (
        <Button type="link" onClick={() => handleDetail(record)}>Detail</Button>
    );

    const handleDetail = (record) => {
        console.log("Detail button clicked for record:", record);
    };

    return (
        <>
            <h1>ExamResult</h1>
            <Table
                dataSource={examResultData}
                rowKey="examItemId"
                loading={loading}
                pagination={{
                    position: ["bottomCenter"],
                }}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            >
                <Column title="Question Number" dataIndex="questionNumber" key="questionNumber" />

                <Column title="Actual Answer" dataIndex="actualAnswer" key="actualAnswer"
                    render={(text, record) => (
                        <span style={{ color: record.status ? 'green' : 'red' }}>{text}</span>
                    )}
                />
                <Column title="Expected Answer" dataIndex="expectedAnswer" key="expectedAnswer" />
                <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    render={(status) => (
                        <Tag color={status ? "green" : "volcano"}>
                            {status ? "Correct" : "Incorrect"}
                        </Tag>
                    )}
                />

            </Table>
        </>
    );
};

export default ExamResultItem;
