import React, { useRef, useEffect } from 'react';
import * as tmPose from '@teachablemachine/pose';
import { useNavigate } from 'react-router-dom';

const PoseModel = () => {
  const canvasRef = useRef(null);
  const labelContainerRef = useRef(null);
  const URL = "https://teachablemachine.withgoogle.com/models/z276Mfgo9/";

  useEffect(() => {
    const loadModel = async () => {
      const modelURL = URL + "model.json";
      const metadataURL = URL + "metadata.json";

      try {
        const model = await tmPose.load(modelURL, metadataURL);
        const maxPredictions = model.getTotalClasses();

        const size = 400; // Increased size
        const flip = true;
        const webcam = new tmPose.Webcam(size, size, flip);
        await webcam.setup();
        await webcam.play();
        window.requestAnimationFrame(loop);

        const canvas = canvasRef.current;
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');

        if (labelContainerRef.current) {
          for (let i = 0; i < maxPredictions; i++) {
            const div = document.createElement('div');
            labelContainerRef.current.appendChild(div);
          }
        }

        async function loop() {
          webcam.update();
          await predict();
          window.requestAnimationFrame(loop);
        }

        async function predict() {
          const { pose, posenetOutput } = await model.estimatePose(webcam.canvas);
          const prediction = await model.predict(posenetOutput);

          if (labelContainerRef.current) {
            for (let i = 0; i < maxPredictions; i++) {
              const classPrediction = `${prediction[i].className}: ${prediction[i].probability.toFixed(2)}`;
              if (labelContainerRef.current.childNodes[i]) {
                labelContainerRef.current.childNodes[i].innerHTML = classPrediction;
                console.log(labelContainerRef.current.childNodes[i].innerHTML);
              }
            }

            if (labelContainerRef.current.childNodes[2] && labelContainerRef.current.childNodes[2].innerHTML === "under 1m: 1.00") {
              canvas.style.border = "5px solid red";
              console.log(labelContainerRef.current.childNodes[2].innerHTML === "under 1m: 1.00");
            } else {
              canvas.style.border = "5px solid green";
            }
          }

          drawPose(pose);
        }

        function drawPose(pose) {
          if (webcam.canvas) {
            ctx.drawImage(webcam.canvas, 0, 0);
            if (pose) {
              const minPartConfidence = 0.5;
              //tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
              //tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
            }
          }
        }
      } catch (error) {
        console.error('Error loading the model:', error);
      }
    };

    loadModel();
  }, []);

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/exam-data');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div><h2>Auto Distance Measurement Demo</h2></div>
      <div>
        <canvas
          ref={canvasRef}
          id="canvas"
          style={{
            border: '5px solid green', /* Default border color */
            borderRadius: '10px',
            margin: '0 auto', /* Center the canvas */
            width: '400px', /* Increased width */
            height: '400px', /* Increased height */
            boxSizing: 'border-box'
          }}
        ></canvas>
      </div>
      <div ref={labelContainerRef} id="label-container" style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}></div>
      <div className="footer">
        <button className="back-button" onClick={handleBackClick}>Back</button>
      </div>
    </div>
  );
};

export default PoseModel;
