import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Table, Button, Form, Input, DatePicker } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import ProfileMeasurementModal from "./ProfileMeasurementModal";
import CreateProfileModal from "./CreateProfileModal";
import { useNavigate } from "react-router-dom";

const { Column } = Table;

const ProfileModal = ({ visible, onCancel, selectedExam, answers, result, eyeSide }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [profileMeasurementModalVisible, setProfileMeasurementModalVisible] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [createProfileModalVisible, setCreateProfileModalVisible] = useState(false);
  const [profilePageIndex, setProfilePageIndex] = useState(1);
  const [profilePageSize] = useState(10);
  const [totalProfiles, setTotalProfiles] = useState(0);

  const navigateTo = useNavigate();
  const UserInfo = JSON.parse(localStorage.getItem('UserInfo'));

  if (!UserInfo) {
    window.location.href = '/login';
  }

  useEffect(() => {
    if (visible) {
      fetchProfiles(profilePageIndex, profilePageSize);
    }
  }, [visible, profilePageIndex, profilePageSize]);

  const fetchProfiles = (pageIndex, pageSize) => {
    setLoading(true);
    axios
      .get(`https://visionup.azurewebsites.net/api/Profile?AccountID=${UserInfo.id}&PageIndex=${pageIndex}&PageSize=${pageSize}`)
      .then((response) => {
        const filteredData = response.data.data.filter(
          (item) => item.status === true
        );
        setData(filteredData);
        setTotalProfiles(response.data.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleRowClick = (record) => {
    setSelectedRow(record.id);
    setSelectedProfile(record);
    setProfileMeasurementModalVisible(true);
  };

  const handleSelectProfileMeasurement = (measurementId) => {
    handleCreateExamResult(measurementId);
  };

  const handleCreateExamResult = async (measurementId) => {
    setLoading(true);
    const requests = [];

    for (let eye of Object.keys(answers)) {
      let answerResult = answers[eye][answers[eye].length - 1];
      const newExamResult = {
        id: 0,
        examDate: new Date().toISOString(),
        profileMeasId: measurementId,
        diopter: answerResult,
        status: true,
        examId: selectedExam,
        eyeSide: eye,
        examResultsItem: [],
      };

      const uniqueItems = new Set();

      answers[eye].slice(0, -1).forEach((item) => {
        const key = `${item.examResultId}_${item.examItemId}`;
        if (!uniqueItems.has(key)) {
          uniqueItems.add(key);
          newExamResult.examResultsItem.push({
            examResultId: 0,
            examItemId: item.examItemId,
            actualAnswer: item.actualAnswer,
            status: item.status,
          });
        }
      });

      console.log("New exam result:", newExamResult);
      requests.push(
        axios.post('https://visionup.azurewebsites.net/api/ExamResult/createFromClient', newExamResult)
      );
    }

    try {
      const responses = await Promise.all(requests);
      if (responses.every((response) => response.data)) {
        toast.success("Exam result created successfully");
        navigateTo(`/exam-result/${measurementId}`);
      } else {
        toast.error("Failed to create exam result");
        onCancel();
      }
    } catch (error) {
      toast.error("Failed to create exam result");
      onCancel();
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setProfilePageIndex(pagination.current);
  };

  const handleCreateProfile = (values) => {
    fetchProfiles(profilePageIndex, profilePageSize);
    setCreateProfileModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Select Profile"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button key="create" type="primary" onClick={() => setCreateProfileModalVisible(true)}>
            Create Profile
          </Button>
        ]}
      >
        <Table
          dataSource={data}
          rowKey="id"
          loading={loading}
          pagination={{
            current: profilePageIndex,
            pageSize: profilePageSize,
            total: totalProfiles,
            showSizeChanger: false
          }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className:
              record.id === selectedRow ? "table-row-selected" : "table-row-hover",
          })}
        >
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="Full Name" dataIndex="fullName" key="fullName" />
          <Column title="Phone" dataIndex="phone" key="phone" />
          <Column
            title="Birthday"
            dataIndex="birthday"
            key="birthday"
            render={(birthday) => moment(birthday).format("DD-MM-YYYY")}
          />
        </Table>
      </Modal>

      <ProfileMeasurementModal
        visible={profileMeasurementModalVisible}
        onCancel={() => setProfileMeasurementModalVisible(false)}
        profile={selectedProfile}
        onSelectProfileMeasurement={handleSelectProfileMeasurement}
      />

      <CreateProfileModal
        visible={createProfileModalVisible}
        onCancel={() => setCreateProfileModalVisible(false)}
        onCreate={handleCreateProfile}
        UserInfo={UserInfo}
      />
    </>
  );
};

export default ProfileModal;
