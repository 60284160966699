import React, { useRef, useEffect } from 'react';
import * as tmPose from '@teachablemachine/pose';

const DirectionModel = ({ setMessage }) => {
  const canvasRef = useRef(null);
  const labelContainerRef = useRef(null);
  const URL = "https://teachablemachine.withgoogle.com/models/3MtOWe0ho/";
  const animationFrameId = useRef(null);
  const webcamRef = useRef(null);
  const ctxRef = useRef(null);

  useEffect(() => {
    const loadModel = async () => {
      const modelURL = URL + "model.json";
      const metadataURL = URL + "metadata.json";

      try {
        const model = await tmPose.load(modelURL, metadataURL);
        const maxPredictions = model.getTotalClasses();

        const size = 200;
        const flip = true;
        const webcam = new tmPose.Webcam(size, size, flip);
        await webcam.setup();
        await webcam.play();
        webcamRef.current = webcam;
        animationFrameId.current = window.requestAnimationFrame(loop);

        const canvas = canvasRef.current;
        if (!canvas) {
          console.error('Canvas element not found');
          return;
        }
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');
        ctxRef.current = ctx;

        for (let i = 0; i < maxPredictions; i++) {
          const div = document.createElement('div');
          labelContainerRef.current.appendChild(div);
        }

        async function loop() {
          webcam.update();
          await predict();
          animationFrameId.current = window.requestAnimationFrame(loop);
        }

        async function predict() {
          const { pose, posenetOutput } = await model.estimatePose(webcam.canvas);
          const prediction = await model.predict(posenetOutput);
          let messageSet = false;
          if (labelContainerRef.current) {
            for (let i = 0; i < maxPredictions; i++) {
              const classPrediction = `${prediction[i].className}: ${prediction[i].probability.toFixed(2)}`;
              labelContainerRef.current.childNodes[i].innerHTML = classPrediction;
              const parts = classPrediction.split(': ');
              const probability = parseFloat(parts[1]);
              const gesture = parts[0];

              if (probability >= 0.80) {
                // Set message based on the class
                switch (gesture) {
                  case 'left':
                    setMessage('Left direction!');
                    break;
                  case 'Right':
                    setMessage('Right direction!');
                    break;
                  case 'Top':
                    setMessage('Top direction!');
                    break;
                  case 'bottom':
                    setMessage('Bottom direction!');
                    break;
                  case 'Default':
                    setMessage('Centered!');
                    break;
                  default:
                    setMessage('Unknown gesture detected!');
                    break;
                }
                messageSet = true;
                break; // Exit the loop once message is set
              }
            }
          }
          
          if (!messageSet) {
            setMessage(''); // Clear message if no gesture has high probability
          }

          drawPose(pose);
        }

        function drawPose(pose) {
          const ctx = ctxRef.current;
          if (webcam.canvas && ctx) {
            ctx.drawImage(webcam.canvas, 0, 0);
            if (pose) {
              const minPartConfidence = 0.5;
              // tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
              // tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
            }
          }
        }
      } catch (error) {
        console.error('Error loading the model:', error);
      }
    };

    loadModel();

    return () => {
      if (animationFrameId.current) {
        window.cancelAnimationFrame(animationFrameId.current);
      }
      if (webcamRef.current) {
        webcamRef.current.stop();
      }
    };
  }, [setMessage]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <canvas 
          ref={canvasRef} 
          id="canvas" 
          style={{
            border: '1px solid blue', 
            borderRadius: '10px',
          }}
        ></canvas>
        <b><div style={{ margin: '10px 0' }}>Move face to center of screen.</div></b>
        <div ref={labelContainerRef} id="label-container"></div>
      </div>
    </div>
  );
};

export default DirectionModel;
