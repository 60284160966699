import { useEffect, useState } from "react";
import { Table, Tag, Button, Avatar } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useNavigate  } from "react-router-dom"; 


const { Column } = Table;

const ExamResult = () => {
  const { id } = useParams();
  const [examResultData, setExamResultData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [examData, setExamData] = useState([]);
  const navigateTo = useNavigate();

  const UserInfo = JSON.parse(localStorage.getItem("UserInfo"));

  if (!UserInfo) {
    window.location.href = '/login';
  }

  useEffect(() => {
    const fetchExamAndResultData = async () => {
      try {
        const [examResponse, examResultResponse] = await Promise.all([
          axios.get("https://visionup.azurewebsites.net/api/Exam/"),
          axios.get(`https://visionup.azurewebsites.net/api/ExamResult/profileMeasurement/${id}`)
        ]);

        const examData = examResponse.data._data;
        const examResultData = examResultResponse.data.map((item) => ({
          ...item,
          exam: examData.find((exam) => exam.id === item.examId),
        }));

        setExamData(examData);
        setExamResultData(examResultData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchExamAndResultData();
  }, []);
 

  const renderActionColumn = (text, record) => (
    <Button type="link" onClick={() => handleDetail(record)}>Detail</Button>
  );

  const handleDetail = (record) => {
    navigateTo(`/exam-result-item/${record.id}`);
  };

  return (
    <>
    <h1>ExamResult</h1>
      <Table
        dataSource={examResultData}
        rowKey="id"
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
        }}
      >
        <Column title="ID" dataIndex="id" key="id" />
        <Column
          title="Exam"
          dataIndex="exam"
          key="exam"
          render={(exam) => (
            <span>{exam ? `${exam.type}` : ""}</span>
          )}
        />
        <Column
          title="Exam Date"
          dataIndex="examDate"
          key="examDate"
          render={(startDate) => moment(startDate).format("DD-MM-YYYY")}
        />
        <Column title="EyeSide" dataIndex="eyeSide" key="eyeSide" />
        <Column title="Diopter" dataIndex="diopter" key="diopter" />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status) => (
            <Tag color={status ? "green" : "volcano"}>
              {status ? "Active" : "Inactive"}
            </Tag>
          )}
        />
        <Column
          dataIndex="action"
          key="action"
          render={renderActionColumn}
        />
      </Table>
    </>
  );
};

export default ExamResult;
