import React, { useEffect, useState } from "react";
import { Modal, Table, Button } from "antd";
import moment from "moment";
import axios from "axios";
import CreateProfileMeasurementModal from "./CreateProfileMeasurementModal";

const { Column } = Table;

const ProfileMeasurementModal = ({
  visible,
  onCancel,
  profile,
  onSelectProfileMeasurement,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [createProfileMeasurementModalVisible, setCreateProfileMeasurementModalVisible] =
    useState(false);

  const [measurementPageIndex, setMeasurementPageIndex] = useState(1);
  const [measurementPageSize] = useState(10);
  const [totalMeasurements, setTotalMeasurements] = useState(0);

  useEffect(() => {
    if (profile) {
      fetchMeasurements(measurementPageIndex, measurementPageSize);
    }
  }, [profile, measurementPageIndex, measurementPageSize]);

  const fetchMeasurements = (pageIndex, pageSize) => {
    setLoading(true);
    axios
      .get(
        `https://visionup.azurewebsites.net/api/ProfileMeasurement?ProfileID=${profile.id}&PageIndex=${pageIndex}&PageSize=${pageSize}`
      )
      .then((response) => {
        const formattedData = response.data.data.map((item) => ({
          ...item,
        }));
        setData(formattedData);
        setTotalMeasurements(response.data.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleRowSelectionChange = (selectedRowKeys) => {
    setSelectedRowKey(selectedRowKeys[0]);
  };

  const handleSave = () => {
    if (selectedRowKey !== null) {
      const selectedMeasurementId = selectedRowKey;
      console.log("Selected measurement ID:", selectedMeasurementId);
      onSelectProfileMeasurement(selectedMeasurementId);
      onCancel();
    }
  };

  const onCreateProfileMeasurement = (newMeasurement) => {
    setData([...data, newMeasurement]);
  };

  const handleMeasurementTableChange = (pagination) => {
    setMeasurementPageIndex(pagination.current);
  };

  return (
    <>
      <Modal
        title="Select profile measurement"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button
            key="create"
            type="primary"
            onClick={() => setCreateProfileMeasurementModalVisible(true)}
          >
            Create Profile Measurement
          </Button>,
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Table
          dataSource={data}
          rowKey="id"
          loading={loading}
          pagination={{
            current: measurementPageIndex,
            pageSize: measurementPageSize,
            total: totalMeasurements,
            showSizeChanger: false,
          }}
          onChange={handleMeasurementTableChange}
          rowSelection={{
            type: "radio",
            selectedRowKeys: [selectedRowKey],
            onChange: handleRowSelectionChange,
          }}
        >
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="Doctor" dataIndex="doctorID" key="doctorID" />
          <Column title="Profile" dataIndex="profileID" key="profileID" />
          <Column
            title="Start Date"
            dataIndex="startDate"
            key="startDate"
            render={(startDate) => moment(startDate).format("DD-MM-YYYY")}
          />
        </Table>
      </Modal>

      <CreateProfileMeasurementModal
        visible={createProfileMeasurementModalVisible}
        onCancel={() => setCreateProfileMeasurementModalVisible(false)}
        onCreate={onCreateProfileMeasurement}
        profile={profile}
      />
    </>
  );
};

export default ProfileMeasurementModal;
