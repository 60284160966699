import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaTrashAlt, FaPlus, FaMinus } from "react-icons/fa";
import { step1 } from "../../assets";
import ConfirmDialog from "../Client/ConfirmDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// IMPORT API SERVICES
import { useEyeGlassService } from "../../services/index";

const CartStep = () => {
  // Data variables
  const UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState({ cartDetails: [], totalItems: 0, totalPrice: 0 });
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Behavior variables
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  // API variables
  const { fetchCartByAccountID, deleteCart } = useEyeGlassService();

  if (!UserInfo) {
    window.location.href = '/login';
  }

  // Fetch all eye glass data and eye glass types
  useEffect(() => {
    const getCartAndOrder = async () => {
      if (UserInfo) {
        const [cartResponse] = await Promise.all([
          fetchCartByAccountID(UserInfo.id)
        ]);
        if (cartResponse && cartResponse.cartDetails.length > 0) {
          setCart(cartResponse);
        } else {
          setError('No data found');
        }
        setLoading(false);
      }
    };

    getCartAndOrder(UserInfo);
  }, []);

  const handleChangeQuality = (product, type) => {
    if (type === "add") {
      if (product.quantity === 99) {
        return;
      }
      let newCart = {
        cartDetails: cart.cartDetails.map((item) => item.productGlassID === product.productGlassID ? { ...item, quantity: item.quantity + 1 } : item),
        totalItems: cart.totalItems + 1,
        totalPrice: cart.totalPrice + product.eyeGlassPrice + product.lensPrice
      };
      setCart(newCart);
    } else {
      if (product.quantity === 1) {
        return;
      }
      let newCart = {
        cartDetails: cart.cartDetails.map((item) => item.productGlassID === product.productGlassID ? { ...item, quantity: item.quantity - 1 } : item),
        totalItems: cart.totalItems - 1,
        totalPrice: cart.totalPrice - product.eyeGlassPrice - product.lensPrice
      }
      setCart(newCart);
    }
  }

  const handleDeleteCartItem = (product) => {
    setSelectedProduct(product);
    setIsDialogOpen(true);
  }

  const handleConfirmDelete = async () => {
    const response = await deleteCart(selectedProduct.accountID, selectedProduct.productGlassID);
    if (response) {
      toast.success("Item deleted successfully");
      setIsDialogOpen(false);
      setSelectedProduct(null);
      let newCart = {
        cartDetails: cart.cartDetails.filter((item) => item.productGlassID !== selectedProduct.productGlassID),
        totalItems: cart.totalItems - 1,
        totalPrice: cart.totalPrice - selectedProduct.eyeGlassPrice - selectedProduct.lensPrice - selectedProduct.lensPrice
      }
      setCart(newCart);
    } else {
      toast.error("Failed to delete item");
      setIsDialogOpen(false);
      setSelectedProduct(null);
    }
  }

  const handleCheckout = () => {
    if (cart.cartDetails.length === 0) {
      toast.error("Cart is empty");
      return;
    }
    navigate("/order-confirm", { state: { cartData: cart, typePayment : "allItem"} });
  }

  if (loading) {
    return <div class="loader-container">
    <div class="loader"></div>
  </div>;
  }
  const formatVND = (priceInVND) => {
    return priceInVND.toLocaleString('vi-VN');
  };
  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg mt-10">
      <div className="w-full flex justify-center items-center">
        <img className="h-[5em] flex items-center" src={step1} />
      </div>

      <h2 className="text-2xl font-bold mb-4">Your Cart</h2>
      <div className="flex flex-col lg:flex-row lg:space-x-6">
        <div className="w-full lg:w-3/4">
          {cart.cartDetails.length > 0 ? (
            cart.cartDetails.map((product, index) => (
              <div
                key={index}
                className="flex items-center mb-4 p-4 border rounded-lg"
              >
                <img
                  src={product.eyeGlassImages[0].url}
                  alt={product.eyeGlassName}
                  className="w-20 h-20 object-cover rounded-lg"
                />
                <div className="ml-4">
                  <h3 className="text-lg font-medium">Glass: {product.eyeGlassName}</h3>
                  <p className="text-gray-600">
                    Price: {formatVND(product.eyeGlassPrice)} VND
                  </p>
                  <h3 className="text-md font-medium">Lens: {product.lensName} <b>x2</b></h3>
                  <p className="text-gray-600">
                    Price: {formatVND(product.lensPrice)} VND
                  </p>
                </div>
                <div className="ml-auto flex items-center">
                  <button onClick={() => handleDeleteCartItem(product)} className="p-2 text-red-600 hover:text-red-800">
                    <FaTrashAlt />
                  </button>
                  <div className="flex items-center border px-3 py-1 ml-4">
                    <button onClick={() => handleChangeQuality(product, "minus")} className="p-1 text-gray-600 hover:text-gray-800">
                      <FaMinus />
                    </button>
                    <span className="px-2">{product.quantity}</span>
                    <button onClick={() => handleChangeQuality(product, "add")} className="p-1 text-gray-600 hover:text-gray-800">
                      <FaPlus />
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-red-600">{error || 'Your cart is empty'}</div>
          )}
        </div>
        <div className="w-full lg:w-1/4">
          <div className="p-4 border rounded-lg">
            <h3 className="text-lg font-medium mb-4">Payment Details</h3>
            <div className="flex justify-between mb-2">
              <span>Subtotal</span>
              <span>{formatVND(cart.totalPrice)} VND</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Shipment cost</span>
              <span>30.000 VND</span>
            </div>
            <div className="flex justify-between font-bold mb-4">
              <span>Grand Total</span>
              <span>{formatVND(cart.totalPrice + 30000)} VND</span>
            </div>
            <button
              onClick={handleCheckout}
              className={`py-3 px-6 rounded-full font-semibold transition duration-200 w-full text-center block ${
                cart.cartDetails.length === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary text-white hover:bg-primary-dark'
              }`}
              disabled={cart.cartDetails.length === 0}
            >
              Proceed to checkout
            </button>
          </div>
        </div>
      </div>

      <ConfirmDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this item?"
      />
    </div>
  );
};

export default CartStep;
