// src/services/ExamService.js
import axios from 'axios';

const getExams = async () => {
  try {
    const response = await axios.get('https://visionup.azurewebsites.net/api/Exam', {
      headers: {
        'Accept': '*/*'
      }
    });

    if (response.data) {
      // Filter the data to include only those with status = true
      const filteredData = response.data._data.filter(item => item.status === true);
      return filteredData;
    } else {
      throw new Error('Failed to fetch data');
    }
  } catch (err) {
    throw err;
  }
};


const getExamItems = async () => {
  try {
    const response = await axios.get('https://visionup.azurewebsites.net/api/ExamItem', {
      headers: {
        'Accept': '*/*'
      }
    });
    console.log(response.data);
    if (response.data) {
      return response.data._data;
      
    } else {
      throw new Error('Failed to fetch data');
    }
  } catch (err) {
    throw err;
  }
};

export { getExams, getExamItems };
