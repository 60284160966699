import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Tag, Avatar, Button } from "antd";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import CreateProfileMeasurementModal from "./CreateProfileMeasurementModal"; // Import your CreateProfileMeasurementModal component

const { Column } = Table;

const ProfileMeasurement = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [createModalVisible, setCreateModalVisible] = useState(false); // State to control visibility of CreateProfileMeasurementModal

  const navigateTo = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://visionup.azurewebsites.net/api/ProfileMeasurement?ProfileID=${id}&PageIndex=${currentPage}&PageSize=${pageSize}`
      );
      const responseData = response.data;
      setData(responseData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, currentPage, pageSize]);

  const handleRowClick = (record) => {
    setSelectedRow(record.id);
    navigateTo(`/Exam-Result/${record.id}`);
  };

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination);
    setPageSize(filters);
  };

  const handleCreateModalOpen = () => {
    setCreateModalVisible(true);
  };

  const handleCreateModalClose = () => {
    setCreateModalVisible(false);
  };

  const handleCreateProfileMeasurement = (newProfileMeasurement) => {
    // Handle the created profile measurement data here
    console.log("Created profile measurement:", newProfileMeasurement);
    // Close the modal if needed
    handleCreateModalClose();
    // You might want to refetch the data to update the table
    fetchData();
  };

  return (
    <>
      <h1>Profile Measurement</h1>
      <Button type="default" onClick={handleCreateModalOpen} style={{ float: 'right', marginRight: '20px' }}>
        Create Profile Measurement
      </Button>
      <Table
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          position: ["bottomCenter"],
          onChange: handleTableChange,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          className: record.id === selectedRow ? "table-row-selected" : "table-row-hover",
        })}
      >
        <Column title="ID" dataIndex="id" key="id" />
        <Column
          title="Doctor"
          dataIndex="doctorID"
          key="doctorID"
          render={(doctorID) => (
            <span>
              <Avatar
                size="small"
                src={`https://i.pravatar.cc/150?u=${doctorID}`}
                style={{ marginRight: 8 }}
              />
              {doctorID}
            </span>
          )}
        />
        <Column title="Profile" dataIndex="profileID" key="profileID" />
        <Column
          title="Start Date"
          dataIndex="startDate"
          key="startDate"
          render={(startDate) => moment(startDate).format("DD-MM-YYYY")}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status) => (
            <Tag color={status ? "green" : "volcano"}>
              {status ? "Active" : "Inactive"}
            </Tag>
          )}
        />
      </Table>

      <CreateProfileMeasurementModal
        visible={createModalVisible}
        onCancel={handleCreateModalClose}
        profile={{ id: id }}
        onCreate={handleCreateProfileMeasurement} // Pass callback to handle creation
      />
    </>
  );
};

export default ProfileMeasurement;
