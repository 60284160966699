import React, { useEffect, useState } from 'react';

const EyeExam = ({ params }) => {
  const [selectedExam, setSelectedExam] = useState(null);

  useEffect(() => {
    const savedSelectedExam = sessionStorage.getItem('selectedExam');
    if (savedSelectedExam) {
      setSelectedExam(parseInt(savedSelectedExam, 10));
    }
  }, []);

  return (
    <div>
      <h2>Eye Exam</h2>
      <img src={`/ExamImg/E.b5d68a9b.svg`} alt="Eye Exam Image" />
      <p>Selected Exam ID: {selectedExam}</p>
      {/* Other elements in the EyeExam component */}
    </div>
  );
};

export default EyeExam;
