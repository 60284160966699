import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Switch from '@mui/material/Switch';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box } from '@mui/material';
import Swal from 'sweetalert2';
import { TailSpin } from 'react-loader-spinner';
import './config/loading.css';


// IMPORT FIREBASE CONFIG
import { imageDb } from "../Admin/config/configFirebase"; // Sử dụng imageDb đã được export từ configFirebase.js
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Xóa getStorage vì không cần thiết
// IMPORT API SERVICES
import { useEyeGlassService } from "../../services/index";
import { useEyeGlassImageService } from "../../services/index";

// Custom styles for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "1200px",
    maxHeight: "90vh",
    overflow: "auto",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const Eyeglasses = () => {
  // Data variables
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [eyeGlassTypes, setEyeGlassTypes] = useState([]);

  // Pagination variables
  const [count, setCount] = useState(0); // Add a count state to store the total number of eyeglasses
  const [pageIndex, setpageIndex] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(count / pageSize);


  // SweetAlert2
  const Swal = require('sweetalert2')


  // Behavior variables
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(null);

  // Modal state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Image variables
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/visionup-ffdbc.appspot.com/o/Other%2FImageDefault.jpg?alt=media&token=7e918d18-af18-4f04-8f43-c62c996dd88a'; // Replace with your actual default image URL
  const angleViewMap = {
    1: 'Front',
    2: 'Back',
    3: 'LeftSide',
    4: 'RightSide',
    5: 'Top',
    6: 'Bottom',
    7: 'Folded',
    8: 'Unfolded'
  };

  //Color Variables
  const [selectedColor, setSelectedColor] = useState(null);
  const colors = [
    { name: 'Red', code: '#FF0000' },
    { name: 'Green', code: '#00FF00' },
    { name: 'Blue', code: '#0000FF' },
    { name: 'Yellow', code: '#FFFF00' },
    { name: 'Gray', code: '#808080' },
    { name: 'Black', code: '#000000' },
    { name: 'White', code: '#FFFFFF' },
  ];

  // Form state
  const [formData, setFormData] = useState({
    name: "",
    eyeGlassTypeID: 1,
    price: 0,
    lensWidth: 0,
    lensHeight: 0,
    bridgeWidth: 0,
    templeLength: 0,
    frameWidth: 0,
    style: "",
    weight: 0,
    design: "",
    status: true,
    quantity: 0,
    material: "",
    color: 0,
  });



  // Validation error state
  const [validationError, setValidationError] = useState("");



  // Search term state
  const [searchTerm, setSearchTerm] = useState("");

  // API variables
  const { fetchAllEyeGlass
    , createEyeGlass
    , fetchEyeGlassById
    , updateEyeGlass
    , countEyeGlass
    , deleteEyeGlass
    , fetchAllEyeGlassTypes
    , validateInput } = useEyeGlassService();
  const { fetchImageByEyeGlassId, postImage, putImage, deleteImage } = useEyeGlassImageService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Define search and pagination parameters
        const params = {
          searchName: searchTerm, // Use the searchTerm state for searching by name
          pageSize: 10,
          pageIndex: pageIndex, // Example page number // Example page size
        };

        const eyeGlassCount = await countEyeGlass(false);
        setCount(eyeGlassCount);

        const eyeGlassData = await fetchAllEyeGlass(params);


        if (eyeGlassData !== null && eyeGlassData.data.length > 0) {
          setData(eyeGlassData.data);
          setTableData(eyeGlassData.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError('No data found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm, pageSize, pageIndex]); // Add searchTerm to the dependency array to refetch when it changes





  useEffect(() => {
    const fetchEyeGlassTypes = async () => {
      try {
        const data = await fetchAllEyeGlassTypes();
        if (data) {
          setEyeGlassTypes(data);
          console.log(data);
        } else {
          throw new Error('Failed to fetch eye glass types');
        }
      } catch (error) {
        console.error('Error fetching eye glass types:', error);
      }
    };

    fetchEyeGlassTypes();
  }, []);


  function handleInputChange(event) {
    const { name, value } = event.target;
    let updatedValue = value;


    // Convert numeric fields to numbers
    const numericFields = ["eyeGlassTypeID", "price", "quantity", "lensWidth", "lensHeight", "bridgeWidth", "templeLength", "frameWidth", "weight"];
    if (numericFields.includes(name)) {
      updatedValue = Number(value);
      console.log('Updated Value:', updatedValue);
      // Ensure the conversion is successful and not NaN; revert to original value if NaN
      if (isNaN(updatedValue)) {
        updatedValue = value;
        console.error(`Failed to convert ${name} to a number:`, value);
      }
    }

    if (name === "status") {
      setFormData(prevFormData => ({
        ...prevFormData,
        status: !prevFormData.status // Toggle the status
      }));
      return; // Early return to prevent further execution
    }

    // Handle color conversion if necessary
    if (name === "color") {
      // Assuming there's a function to map color names to their IDs
      updatedValue = mapColorNameToId(value);
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: updatedValue
    }));
  }


  const handleColorSelect = (colorName) => {
    // Assuming there's a function to map color names to their IDs
    const colorId = mapColorNameToId(colorName);
    setSelectedColor(colorName);
    console.log('Selected color:', colorName, colorId);
    setFormData(prevFormData => ({
      ...prevFormData,
      color: colorId,
    }));
  };

  function mapColorNameToId(colorName) {
    const colorMappings = {
      Red: 1,
      Green: 2,
      Blue: 3,
      Yellow: 4,
      Gray: 5,
      Black: 6,
      White: 7,
      // Add other colors as needed
    };
    return colorMappings[colorName] || 0; // Default to 0 if color not found
  }

  // Function to fetch image data by eyeGlass ID
  const fetchDataImage = async (id) => {
    try {
      console.log('ID:', id);
      // Check if id is null, indicating a new addition
      if (id === null) {
        const defaultImages = Array(8).fill(null).map((_, index) => ({
          id: null,
          eyeGlassID: null,
          angleView: index + 1,
          url: null,
          isNew: true // All images are new
        }));
        setImages(defaultImages);
        console.log('Default images for new addition:', defaultImages);
      } else {
        const eyeGlassId = id; // Use the actual eyeglass ID
        const fetchedImagesData = await fetchImageByEyeGlassId(eyeGlassId);
        if (fetchedImagesData) {
          const defaultImages = Array(8).fill(null).map((_, index) => ({
            id: index + 1,
            eyeGlassID: parseInt(eyeGlassId),
            angleView: index + 1,
            url: null,
            isNew: true // Mark all default images as new
          }));

          const filteredImages = fetchedImagesData
            .filter(image => Object.keys(angleViewMap).includes(image.angleView.toString()))
            .map(image => ({
              id: image.id,
              eyeGlassID: image.eyeGlassID,
              angleView: image.angleView,
              url: image.url,
              isNew: !image.url // New if URL is not the defaultImageUrl
            }));

          const finalImages = defaultImages.map(defaultImage => {
            const fetchedImage = filteredImages.find(image => image.angleView === defaultImage.angleView);
            if (fetchedImage) {
              return {
                ...fetchedImage,
                isNew: !fetchedImage.url
              };
            }
            return defaultImage;
          });

          setImages(finalImages);
          console.log('Images fetched:', finalImages);
        }
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleAddNewBtn = async (e) => {
    fetchDataImage(null);
    openModal();
  };

  // Function to handle view/edit an eyeglass
  const handleViewEdit = async (item) => {
    try {
      const eyeGlassDetails = await fetchEyeGlassById(item.id);
      if (eyeGlassDetails) {
        setFormData({
          id: eyeGlassDetails.id,
          name: eyeGlassDetails.name,
          eyeGlassTypeID: eyeGlassDetails.eyeGlassTypeID, // Assuming the API returns an eyeGlassTypeID
          price: eyeGlassDetails.price,
          lensWidth: eyeGlassDetails.lensWidth,
          lensHeight: eyeGlassDetails.lensHeight,
          bridgeWidth: eyeGlassDetails.bridgeWidth,
          templeLength: eyeGlassDetails.templeLength,
          frameWidth: eyeGlassDetails.frameWidth,
          style: eyeGlassDetails.style,
          weight: eyeGlassDetails.weight,
          design: eyeGlassDetails.design,
          status: eyeGlassDetails.status,
          quantity: eyeGlassDetails.quantity,
          material: eyeGlassDetails.material,
          color: eyeGlassDetails.color, // Assuming the API returns a color ID
        });

        fetchDataImage(item.id);

        setIsEditing(true);
        openModal();
      } else {
        console.log('No eyeglass details found for ID:', item.id);
      }
    } catch (error) {
      console.error('Error fetching eyeglass details:', error);
    }
  };


  // Function to handle update of an eyeglass
  // Function to handle update of an eyeglass
  const handleUpdate = async (e) => {
    e.preventDefault();
    setShowLoader(true); // Show Loader
    try {
      const errors = validateInput(formData);
      if (Object.keys(errors).length > 0) {
        setValidationError(errors);
        const firstErrorKey = Object.keys(errors)[0];
        toast.error(`Validation error: ${errors[firstErrorKey]}`);
        setShowLoader(false); // Hide Loader in case of validation error
        return;
      }

      console.log("FormData Before Update:", formData);
      const updatedEyeGlass = await updateEyeGlass(formData);

      if (updatedEyeGlass) {
        // Lọc ra những hình ảnh có URL
        console.log('Images to save Before:', images);
        const imagesToSave = images.filter(image => image.url);

        console.log('Images to save:', imagesToSave);
        // Chỉ thực hiện saveImages nếu có ít nhất một hình ảnh để lưu
        if (imagesToSave.length > 0) {
          await saveImages(imagesToSave); // Ensure saveImages is awaited
        }

        const updatedTableData = tableData.map((item) =>
          item.id === updatedEyeGlass.id ? { ...item, ...updatedEyeGlass } : item
        );
        setTableData(updatedTableData);

        setValidationError({});
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Eyeglass updated successfully.",
          showConfirmButton: false,
          timer: 1500
        });
        closeModal();
      } else {
        throw new Error("Failed to update eyeglass.");
      }
    } catch (error) {
      console.error("Error updating eyeglass:", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Failed to update eyeglass.",
        showConfirmButton: false,
        timer: 1500
      });
    } finally {
      setShowLoader(false); // Hide Loader regardless of the outcome
    }
  };

  // Modified handleAdd function with loading
  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const errors = validateInput(formData);
      if (Object.keys(errors).length > 0) {
        setValidationError(errors);
        const firstErrorKey = Object.keys(errors)[0];
        toast.error(`Validation error: ${errors[firstErrorKey]}`);
        setLoading(false); // Stop loading in case of validation error
        return;
      }

      console.log("FormData Before Add:", formData);
      const newEyeGlass = await createEyeGlass(formData);

      if (newEyeGlass && newEyeGlass.id) {
        const imagesWithData = images.filter(image => image.url && image.url.trim() !== '');

        imagesWithData.forEach(image => {
          image.eyeGlassID = newEyeGlass.id;
        });
        console.log('Images to save:', imagesWithData);
        await saveImages(imagesWithData);

        setTableData([...tableData, newEyeGlass]);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Eyeglass added successfully.",
          showConfirmButton: false,
          timer: 1500
        });
        closeModal();
      } else {
        throw new Error("Failed to add eyeglass.");
      }
    } catch (error) {
      console.error("Error adding eyeglass:", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Failed to add eyeglass.",
        showConfirmButton: false,
        timer: 1500
      });
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  // Function to handle delete an eyeglass
  const handleDelete = (eyeGlassId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this eyeglass?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              console.log(eyeGlassId);
              await deleteEyeGlass(eyeGlassId);
              // Fetch lại dữ liệu sau khi xóa thành công
              const eyeGlassData = await fetchAllEyeGlass();
              if (eyeGlassData !== null && eyeGlassData.data.length > 0) {
                setData(eyeGlassData.data);
                setTableData(eyeGlassData.data);
              } else {
                setData([]);
                setTableData([]);
                setError('No data found');
              }
              toast.success("Eyeglass deleted successfully.");
            } catch (error) {
              console.error("Error:", error);
              toast.error("Failed to delete eyeglass.");
            }
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  // Function to handle image upload
  const handleImageUpload = async (imageIndex) => {
    console.log('Image Test', images);
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        // Hiển thị confirm dialog trước khi upload
        Swal.fire({
          title: "Are you sure to upload this image?",
          text: "This image will upload to Firebase!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, upload it!"
        }).then((result) => {
          if (result.isConfirmed) {
            // Nếu người dùng xác nhận, tiến hành upload ảnh
            const folderName = 'EyeGlasses';
            const storageRef = ref(imageDb, `Products/${folderName}/${file.name}`);
            uploadBytes(storageRef, file).then((snapshot) => {
              console.log('Uploaded a blob or file!', snapshot);
              getDownloadURL(snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                const newImages = [...images];
                newImages[imageIndex].url = downloadURL;
                setImages(newImages);
                console.log('Image updated index:', newImages[imageIndex]);
                // Hiển thị thông báo thành công
                Swal.fire({
                  title: "Uploaded!",
                  text: "Your image has been uploaded.",
                  icon: "success"
                });
              });
            }).catch((error) => {
              console.error("Error uploading file: ", error);
            });
          }
        });
      }
    };
    fileInput.click();
  };

  // Function to handle update of an eyeglass
  async function saveImages(images) {
    for (const image of images) {
      if (image.isNew) {
        // Sử dụng API POST để thêm ảnh mới
        const { id, eyeGlassID, url, angleView } = image;
        console.log('Image to save:', image);
        await postImage(id, eyeGlassID, url, angleView);
        // if (response.success) {
        //   image.isNew = false; // Cập nhật trạng thái của ảnh sau khi thêm thành công
        // }
      } else {
        // Sử dụng API PUT để cập nhật ảnh cũ
        const { id, eyeGlassID, url, angleView } = image;
        await putImage(id, eyeGlassID, url, angleView);
      }
    }
  }


  // Function to handle search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Function to filter table data based on search term
  const filteredTableData = tableData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to open modal for adding or editing an eyeglass
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Function to close modal and reset form data
  const closeModal = () => {
    setModalIsOpen(false);
    setFormData({
      id: 0,
      name: "",
      category: "",
      price: 0,
    });
    setImages([]);
    setValidationError("");
    setIsEditing(false);
  };

  const Pagination = ({ pageIndex, totalPages, onPageChange, totalRecords }) => {
    // Ensure pageIndex, totalPages, and totalRecords are treated as numbers and provide default values if they are not valid
    const currentPageIndex = Number(pageIndex) || 1;
    const currentTotalPages = Number(totalPages) || 1;
    const currentTotalRecords = Number(totalRecords) || 0;

    // Generate page index buttons
    const pageIndexButtons = Array.from({ length: currentTotalPages }, (_, i) => i + 1).map(pageNum => (
      <button
        key={pageNum}
        disabled={currentPageIndex === pageNum}
        onClick={() => onPageChange(pageNum)}
        className={`px-2 py-1 text-gray-800 rounded ${currentPageIndex === pageNum ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
        aria-label={`Go to page ${pageNum}`}
      >
        {pageNum}
      </button>
    ));

    // Calculate the range of records being shown
    const startRecord = (currentPageIndex - 1) * 10 + 1;
    const endRecord = Math.min(currentPageIndex * 10, currentTotalRecords);

    return (


      <div className="flex justify-between items-center w-full">
        <span className="text-gray-700">{`Showing ${startRecord} to ${endRecord} of ${currentTotalRecords} Records`}</span>
        <div className="flex justify-center items-center space-x-2">
          <button
            disabled={currentPageIndex <= 1}
            onClick={() => onPageChange(currentPageIndex - 1)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
            aria-label="Previous page"
          >
            Prev
          </button>
          {pageIndexButtons}
          <button
            disabled={currentPageIndex >= currentTotalPages}
            onClick={() => onPageChange(currentPageIndex + 1)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
            aria-label="Next page"
          >
            Next
          </button>
        </div>
      </div>

    );
  };

  const handlePageChange = (newPage) => {
    setpageIndex(newPage);
  };

  if (loading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {showLoader && (
        <div className="loader-overlay">
          <TailSpin color="#00BFFF" height={80} width={80} />
        </div>
      )}      {<div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Eyeglasses Management</h1>
        <div className="flex justify-between mb-4">
          <input
            type="text"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-300 p-2 rounded"
          />
          <button
            onClick={() => {
              handleAddNewBtn();
            }}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Add
          </button>
        </div>
        <TableContainer component={Paper} elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242' }}>Product ID</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242' }}>Name</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242' }}>Category</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242' }}>Price</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242' }}>Quantity</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242' }}>Status</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#424242' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTableData.map((item) => (
                <TableRow key={item.id} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.eyeGlassType ? item.eyeGlassType.glassType : 'N/A'}</TableCell>
                  <TableCell align="center">${item.price}</TableCell>
                  <TableCell align="center">{item.quantity}</TableCell>
                  <TableCell align="center">
                    <Box sx={{
                      display: 'inline-block',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      backgroundColor: item.status ? '#C8E6C9' : '#FFCDD2',
                      color: item.status ? 'green' : 'red'
                    }}>
                      {item.status ? 'Active' : 'Inactive'}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ marginRight: '8px' }}
                      onClick={() => handleViewEdit(item)}
                    >
                      Detail
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="mt-4">
          <Pagination
            pageIndex={pageIndex}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalRecords={count}
          />
        </div>


        {/* Modal for adding or editing an eyeglass */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Eyeglass Modal"
        >
          <h2 className="text-2xl font-bold mb-4">
            {isEditing ? "Edit Eyeglass" : "Add Eyeglass"}
          </h2>
          <form onSubmit={isEditing ? handleUpdate : handleAdd}>
            <div className="flex">
              <div className="flex-1 mr-4">
                {/* Existing fields */}
                <div className="mb-4">
                  <label className="block text-sm font-bold mb-2">Name</label>
                  <input
                    type="text"
                    className="border border-gray-300 p-2 rounded w-full"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                  {validationError.name && <p className="text-red-500 text-xs mt-1">{validationError.name}</p>}
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-bold mb-2">Category</label>
                  <select
                    className="border border-gray-300 p-2 rounded w-full"
                    name="eyeGlassTypeID"
                    value={formData.eyeGlassTypeID}
                    onChange={handleInputChange}
                    required
                  >
                    {eyeGlassTypes.map((type) => (
                      <option key={type.id} value={type.id}> {/* Use type.id instead of type.glassType */}
                        {type.glassType}
                      </option>
                    ))}
                  </select>
                  {validationError.name && <p className="text-red-500 text-xs mt-1">{validationError.name}</p>}

                </div>
                <div className="mb-4">
                  <label className="block text-sm font-bold mb-2">Price</label>
                  <input
                    type="number"
                    className="border border-gray-300 p-2 rounded w-full"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                {/* New fields */}
                <div className="mb-4">
                  <label className="block text-sm font-bold mb-2">Quantity</label>
                  <input
                    type="number"
                    className="border border-gray-300 p-2 rounded w-full"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-bold mb-2">Material</label>
                  <select
                    className="border border-gray-300 p-2 rounded w-full"
                    name="material"
                    value={formData.material}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a material</option> {/* Option mặc định */}
                    <option value="metal-titanium">Metal - Titanium</option>
                    <option value="metal-stainless-steel">Metal - Stainless Steel</option>
                    <option value="metal-aluminum">Metal - Aluminum</option>
                    <option value="metal-monel">Metal - Monel</option>
                    <option value="plastic-cellulose-acetate">Plastic - Cellulose Acetate</option>
                    <option value="plastic-tr-90">Plastic - TR-90</option>
                    <option value="plastic-nylon">Plastic - Nylon</option>
                    <option value="wood">Wood</option>
                    <option value="horn-and-bone">Horn and Bone</option>
                    <option value="combination">Combination</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-bold mb-2">Color</label>
                  <div className="flex flex-wrap">
                    {colors.map((color) => (
                      <div
                        key={color.name}
                        onClick={() => handleColorSelect(color.name)}
                        style={{ backgroundColor: color.code }}
                        className={`w-12 h-12 cursor-pointer m-1 rounded ${selectedColor === color.name ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`} // Thêm hiệu ứng viền cho màu được chọn
                      ></div>
                    ))}
                  </div>
                </div>
                <div>
                  <p></p>
                  <input type="number" name="lensWidth" value={formData.lensWidth} onChange={handleInputChange} placeholder="Lens Width" title="Enter the lens width in millimeters" />
                  <input type="number" name="lensHeight" value={formData.lensHeight} onChange={handleInputChange} placeholder="Lens Height" title="Enter the lens height in millimeters" />
                  <input type="number" name="bridgeWidth" value={formData.bridgeWidth} onChange={handleInputChange} placeholder="Bridge Width" title="Enter the bridge width in millimeters" />
                  <input type="number" name="templeLength" value={formData.templeLength} onChange={handleInputChange} placeholder="Temple Length" title="Enter the temple length in millimeters" />
                  <input type="number" name="frameWidth" value={formData.frameWidth} onChange={handleInputChange} placeholder="Frame Width" title="Enter the frame width in millimeters" />
                  <input type="text" name="style" value={formData.style} onChange={handleInputChange} placeholder="Style" title="Enter the style of the eyeglasses" />
                  <input type="number" name="weight" value={formData.weight} onChange={handleInputChange} placeholder="Weight" title="Enter the weight of the eyeglasses in grams" />
                  <input type="text" name="design" value={formData.design} onChange={handleInputChange} placeholder="Design" title="Enter the design details of the eyeglasses" />
                </div>
                <label>
                  Status:
                  <Switch
                    checked={formData.status}
                    onChange={e => handleInputChange({ target: { name: 'status', value: e.target.checked } })}
                    name="status"
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </label>


              </div>
              <div className="flex-1 mt-7 ml-4">
                <div className="max-w-4xl mx-auto">
                  <div className="grid grid-cols-3 gap-4">
                    {images.map((imageData, index) => (
                      <div key={imageData.id} className="relative">
                        {/* Use the mapping here to display the angle name */}
                        <h2 className="text-lg font-medium mb-2">{angleViewMap[imageData.angleView] || imageData.angleView}</h2>
                        <div
                          className="border-dashed border-2 border-gray-300 h-48 flex items-center p-4 cursor-pointer"
                          onClick={() => handleImageUpload(index)}
                        >
                          <img src={imageData.url ? imageData.url : defaultImageUrl} alt={`View ${angleViewMap[imageData.angleView] || imageData.angleView}`} className="h-full w-full object-cover" style={{ objectFit: 'cover' }} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-12">
              <button
                type="button"
                onClick={closeModal}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                {isEditing ? "Update" : "Add"}
              </button>
            </div>
          </form>

        </Modal>

        <ToastContainer />
      </div>}
    </div>

  );
};

export default Eyeglasses;
