import React from "react";
import { Modal, Form, Input, DatePicker, Button } from "antd";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

const CreateProfileModal = ({ visible, onCancel, onCreate, UserInfo }) => {
  const [form] = Form.useForm();

  const handleCreateProfile = (values) => {
    const newProfile = {
      ...values,
      accountID: UserInfo.id,
      birthday: values.birthday.toISOString(),
      status: true,
    };

    axios
      .post("https://visionup.azurewebsites.net/api/Profile", newProfile)
      .then((response) => {
        console.log("Profile created:", response.data);
        toast.success("Profile created successfully");
        onCreate(response.data);
        form.resetFields();
      })
      .catch((error) => {
        console.error("Error creating profile:", error);
        toast.error("Failed to create profile");
      });
  };

  return (
    <Modal
      title="Create Profile"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered // Để đưa modal vào giữa
    >
      <Form form={form} onFinish={handleCreateProfile}>
        <Form.Item
          name="fullName"
          label="Full Name"
          rules={[{ required: true, message: "Please input the full name!" }]}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "Please input the phone number!" }]}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Please input the address!" }]}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="urlImage"
          label="Image URL"
          rules={[{ type: 'url', message: 'Please enter a valid URL!' }]}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="birthday"
          label="Birthday"
          rules={[{ required: true, message: "Please select the birthday!" }]}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProfileModal;
