import axios from "axios";


export const useEyeGlassImageService = () => {
    const baseUrl = process.env.REACT_APP_API_URL;

    const fetchImageByEyeGlassId = async (id) => {
        return axios.get(`${baseUrl}/api/EyeGlassImage/eyeGlass/${id}`)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    console.log('No item found in response');
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    };

    const postImage = async (id, eyeGlassID, url, angleView) => {
        const data = {
            id,
            eyeGlassID,
            url,
            angleView
        };
    
        try {
            const response = await axios.post(`${baseUrl}/api/EyeGlassImage`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };
    
    const putImage = async (id, eyeGlassID, url, angleView) => {
        const data = {
            id,
            eyeGlassID,
            url,
            angleView
        };
    
        try {
            const response = await axios.put(`${baseUrl}/api/EyeGlassImage`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const deleteImage = async (id) => {
        try {
            const response = await axios.delete(`${baseUrl}/api/EyeGlassImage/${id}`);
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    return {
        fetchImageByEyeGlassId,
        postImage,
        putImage,
        deleteImage
    };
}
