import React, { useState, useEffect } from 'react';
import { getExams, getExamItems } from '../../services/ExamService';
import '../../ExamData.css';
import { toast } from "react-toastify";
import ProfileModal from './ProfileModal';
import { useNavigate } from 'react-router-dom';
import DirectionModel from './DirectionModel/DirectionModel';

const ExamData = () => {
  const [examData, setExamData] = useState([]);
  const [examItems, setExamItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedExam, setSelectedExam] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [numberOfTest, setNumberOfTest] = useState(0);
  const [numberOfSuccess, setNumberOfSuccess] = useState(0);
  const [numberOfFail, setNumberOfFail] = useState(0);
  const [numberOfFailLv8, setNumberOfFailLv8] = useState(0);
  const [result, setResult] = useState(0);
  const [initialLevel, setInitialLevel] = useState(null);
  const [testDistance, setTestDistance] = useState(1.5); // Default distance to 3m
  const [desiredTests, setDesiredTests] = useState(0); // Number of desired tests
  const [answers, setAnswers] = useState([]); // State to store answers
  const [showDiv2, setShowDiv2] = useState(false);
  const [showDiv3, setShowDiv3] = useState(false);
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv4, setShowDiv4] = useState(false); // State for div4 visibility
  const [nextError, setNextError] = useState(''); // State for error message
  const [currentEye, setCurrentEye] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [message, setMessage] = useState('');
  const [currentDirection, setCurrentDirection] = useState('');
  const navigate = useNavigate();
  const heightValues = {
    1: 0.173,
    2: 0.26,
    3: 0.35,
    4: 0.44,
    5: 0.6,
    6: 0.86,
    7: 1.73,
    8: 2.5,
  };

  const widthValues = {
    1: 0.173,
    2: 0.26,
    3: 0.35,
    4: 0.44,
    5: 0.6,
    6: 0.86,
    7: 1.73,
    8: 2.5,
  };

  useEffect(() => {
    // Effect to update current direction based on message
    switch (message) {
      case 'Left direction!':
        setCurrentDirection('left');
        break;
      case 'Right direction!':
        setCurrentDirection('right');
        break;
      case 'Top direction!':
        setCurrentDirection('top');
        break;
      case 'Bottom direction!':
        setCurrentDirection('bottom');
        break;
      case 'Centered!':
        setCurrentDirection('center');
        break;
      default:
        setCurrentDirection('');
        break;
    }
  }, [message]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const exams = await getExams();
        const items = await getExamItems();
        setExamData(exams);
        setExamItems(items);
      } catch (err) {
        setError('Failed to fetch data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleNextClick = () => {
    if (showDiv1) {
      if (selectedExam === 1) {
        setShowDiv1(false);
        setShowDiv4(true); // Show div4 after selecting exam
        toast.error('Choose Type Exam, Please.');
      } else {
        toast.error('Test does not exist. Will be available soon.');
      }
    } else if (showDiv2) {
      setShowDiv2(false);
      setShowDiv3(true);
    }
  };

  const handleBackClick = () => {
    if (showDiv2) {
      setShowDiv2(false);
      setShowDiv4(true);
      handleSummitClick();
    } else if (showDiv3) {
      setShowDiv3(false);
      setShowDiv2(true);
    } else if (showDiv4) {
      setShowDiv4(false);
      setShowDiv1(true);
    } else if (showDiv1) {
      navigate("/");
    }

  };
  const handleSummitClick = () => {
    setShowDiv2(false);
    setShowDiv4(true);

    setAnswers(prevAnswers => {
      const newAnswers = { ...prevAnswers };
      if (!Array.isArray(newAnswers[currentEye])) {
        newAnswers[currentEye] = []; // Initialize as an empty array if it's not already an array
      }
      newAnswers[currentEye] = [...newAnswers[currentEye], Sum()];
      return newAnswers;
    });
    setInitialLevel(1);
    setNumberOfTest(0);
    setNumberOfSuccess(0);
    setNumberOfFailLv8(0);
    setNumberOfFail(0);
    setResult(0);
    setCurrentExamItem(currentItem.id, 1);
    setShowModal(false);

  };
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleProfileModal = () => {
    setShowProfileModal(!showProfileModal); // Toggle the visibility of ProfileModal
  };
  const handleRadioChange = (id) => {
    setSelectedExam(id);
    sessionStorage.setItem('selectedExam', id.toString());
  };

  const handleDirectionClick = (angle) => {
    const currentItem = examItems[currentIndex];
    if (!currentItem) return;

    if (initialLevel === null) {
      setInitialLevel(currentItem.level); // Chỉ gán initialLevel một lần
    }

    const currentLevel = initialLevel;

    setSelectedAnswer(angle);

    const answerStatus = angle === currentItem.expectedAnswer;

    // Add answer to the array
    const newAnswer = {
      examItemId: currentItem.id,
      actualAnswer: angle,
      status: answerStatus
    };

    setAnswers(prevAnswers => {
      const newAnswers = { ...prevAnswers };
      if (!Array.isArray(newAnswers[currentEye])) {
        newAnswers[currentEye] = []; // Initialize as an empty array if it's not already an array
      }
      newAnswers[currentEye] = [...newAnswers[currentEye], newAnswer];
      return newAnswers;
    });


    setNumberOfTest(numberOfTest + 1);
    if (answerStatus) {
      setNumberOfFail(0);
      setNumberOfSuccess(numberOfSuccess + 1);
      if (numberOfSuccess === 6) {
        setNumberOfTest(0);
        setResult(0);
        Sum();
      } else if (currentLevel === 1 && numberOfSuccess >= 2) {
        setCurrentExamItem(currentItem.id, initialLevel + 1);
        console.log("+1 level =1 and numberofSucces >=2");
      } else if (currentLevel !== 1 && numberOfSuccess === 4) {
        console.log("-1 level !=1 and numberofSucces ==5");
        setNumberOfSuccess(0);
        setCurrentExamItem(currentItem.id, initialLevel - 1);
        console.log("level - " + currentLevel);
      }
    } else {
      setNumberOfSuccess(0);
      setNumberOfFail(numberOfFail + 1);
      if (currentLevel !== 8 && numberOfFail % 2 === 0) {
        setCurrentExamItem(currentItem.id, initialLevel + 1);
        console.log("+1 level !=5 and numberofFail % 2 == 0");
      } else if (currentLevel === 8 && numberOfFail === 2) {
        setCurrentExamItem(currentItem.id, initialLevel - 1);
        console.log("-1 level ==5 and numberofFail == 2");
      } else if (currentLevel === 8 && numberOfFailLv8 === 4) {
        Sum();
      } else if (currentLevel === 8) {
        setNumberOfFailLv8(numberOfFailLv8 + 1);
      }
      setResult(result + (currentItem.myopia >= 1.5 ? currentItem.myopia * 3 : currentItem.myopia));
    }
    if (Sum() > 2) {
      console.log("sum>2" + Sum());
      handleSummitClick();
    } else if (numberOfTest % 20 === 0 && numberOfTest !== 0) {
      toggleModal();
    }
    console.log("success " + numberOfSuccess + " fail " + numberOfFail + " faillv8 "
      + numberOfFailLv8 + " result " + result + " test " + numberOfTest + " level " + initialLevel + "currentItem.myopia " + currentItem.myopia + "currentIndex" + currentIndex);

    const sameLevelItems = examItems.filter(item => item.level === initialLevel);
    if (sameLevelItems.length > 0) {
      const randomIndex = Math.floor(Math.random() * sameLevelItems.length);
      const nextItem = sameLevelItems[randomIndex];
      console.log(sameLevelItems);
      const nextIndex = examItems.indexOf(nextItem);
      setCurrentIndex(nextIndex);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % examItems.length);
    }
  };

  const setCurrentExamItem = (id, newLevel) => {
    console.log(`Updating item ${id} to new level ${newLevel}`);
    setInitialLevel(newLevel);
    setExamItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, level: newLevel } : item
      )
    );
  };

  useEffect(() => {
    const savedSelectedExam = sessionStorage.getItem('selectedExam');
    if (savedSelectedExam) {
      setSelectedExam(parseInt(savedSelectedExam, 10));
    }
  }, []);

  // Save answers to session storage
  useEffect(() => {
    sessionStorage.setItem('answers', JSON.stringify(answers));
  }, [answers]);

  // Handle test click for div4
  const handleTestClick = (eye) => {
    console.log(`Testing ${eye} eye`);
    console.log(eye);
    setCurrentEye(eye);
    setShowDiv4(false);
    setShowDiv2(true);
    setResult(0);
    setCurrentIndex(0);
    setInitialLevel(1);
    setCurrentExamItem(currentItem.id, 1);
    setAnswers(prevAnswers => {
      const newAnswers = { ...prevAnswers };
      newAnswers[eye] = []; // Reset the session for the current eye
      return newAnswers;
    });
  };

  if (isLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const currentItem = examItems[currentIndex];

  const onResultClick = () => {
    if (Object.keys(answers).length > 0 && answers.leftEye && answers.rightEye) {
      toggleProfileModal();
    } else {
      toast.error('You must test both eyes before viewing the results.');
    }
  };

  const Sum = () => {
    const sum = result / numberOfTest;
    return Math.round(sum * 100) / 100;
  };
  const SumEye = (eye) => {
    const eyeAnswers = answers[eye] || [];
    const totalQuestions = eyeAnswers.length;
    const correctAnswers = eyeAnswers.filter(answer => answer.status).length;
    return parseInt((correctAnswers / totalQuestions) * 100);
  };
  let imageSizeStyle = {};
  if (currentItem) {
    const level = currentItem.level;
    const heightInCm = heightValues[level];
    const widthInCm = widthValues[level];
    const cmToPixelRatio = 96 / 2.54;

    // Adjust size based on the test distance (3m is the reference distance)
    const heightInPixel = (heightInCm * cmToPixelRatio) * (testDistance / 3);
    const widthInPixel = (widthInCm * cmToPixelRatio) * (testDistance / 3);

    imageSizeStyle = {
      height: `${heightInPixel}px`,
      width: `${widthInPixel}px`,
    };


  }
  const handleDistanceClick = () => {
    navigate('/posemodel');
  };

  return (
    <div className="exam-container">

      {showDiv1 ? (
        <div className="div1">
          <h2>Exam Type And Distance</h2>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '1', width: '50%', height: 'auto', margin: '0px 10px', marginTop: '20px' }}>
              <b><h3 style={{marginBottom:'10px'}} >Choose Type Exam</h3></b>
              <div className="exam-list">
                {examData.map(exam => (
                  <div key={exam.id} className="exam-item">
                    <div className="exam-info">
                      <input
                        type="radio"
                        name="exam"
                        checked={selectedExam === exam.id}
                        onChange={() => handleRadioChange(exam.id)}
                      />
                      {exam.type}
                      {/* - Status: {exam.status ? 'Passed' : 'Failed'} */}
                    </div>
                  </div>
                ))}
              </div>
              {nextError && <p className="error-message">{nextError}</p>}
            </div>
            <div style={{ flex: '1', width: '50%', height: 'auto', margin: '0px 10px', marginTop: '20px' }}>
              <h4>This test can measure your vision at any distance.
                <br></br>The standard distance is 1.5 meters.</h4>
              <img src="https://firebasestorage.googleapis.com/v0/b/visionup-ffdbc.appspot.com/o/Other%2Fdistance.png?alt=media&token=285804e5-306d-4db4-9de0-227d2b9802bf" alt="Exam item" className='distance-image' />

              <div className="control-panel" style={{ whiteSpace: 'nowrap', marginTop: '40px' }}>
                <label >
                  Your Test Distance:
                  <input
                    type="range"
                    min="1"
                    max="5"
                    step="0.1"
                    value={testDistance}
                    onChange={(e) => setTestDistance(parseFloat(e.target.value))}
                  />
                  <span >
                    <input
                      type="number"
                      step="0.1"
                      min="1"
                      max="5"
                      value={testDistance}
                      onChange={(e) => setTestDistance(parseFloat(e.target.value))}
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                    />
                    Meters
                  </span>
                </label><br />
                <button style={{marginTop:'20px'}} className="next-button" onClick={handleDistanceClick}>Distance (Demo)</button>
              </div>

              <div className="footer">
                <button className="back-button" onClick={handleBackClick}>Back</button>
                <button className="next-button" onClick={handleNextClick}>Next</button>
              </div>
            </div>
          </div>
          <br />
        </div>
      ) : showDiv4 ? (
        <div className="eye-test-selection">
          <h2>Select Eye to Test</h2>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <div style={{ flex: '1', margin: '0 10px', textAlign: 'center' }}>
              <button className='btn-select' onClick={() => handleTestClick('leftEye')}>Test Left Eye</button>
              <div className='result-test'>
                <b><h3>Left Eye Results</h3></b>
                <p>Left Eye Score:<br /> {SumEye('leftEye') ? `${SumEye('leftEye')}%` : 'No results yet'}</p>
                <p>Myopia Left Eye:<br />
                  {answers.leftEye && answers.leftEye.length > 0 ?
                    answers.leftEye[answers.leftEye.length - 1] :
                    'Not yet'}
                </p>
              </div>

            </div>
            <div style={{ flex: '1', margin: '0 10px', textAlign: 'center', width: '40%', maxWidth: '300px', minWidth: '200px' }}>
              <button className='btn-select' onClick={() => handleTestClick('rightEye')}>Test Right Eye</button>
              <div className='result-test'>
                <b><h3>Right Eye Results</h3></b>
                <p>Right Eye Score:<br /> {SumEye('rightEye') ? `${SumEye('rightEye')}%` : 'No results yet'}</p>
                <p>Myopia Right Eye:<br />
                  {answers.rightEye && answers.rightEye.length > 0 ?
                    answers.rightEye[answers.rightEye.length - 1] :
                    'Not yet'}
                </p>
              </div>

            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className='btn-result' onClick={onResultClick}>Result</button>
          </div>
          <div className="footer">
            <button className="back-button" onClick={handleBackClick}>Back</button>
          </div>
        </div>

      ) : showDiv2 ? (
        <div className="div2" style={{ margin: '0 20px', marginRight: '20px' }}>
          <h2 style={{ marginBottom: '50px' }}>
            Test {currentEye === 'leftEye' ? 'Left' : 'Right'} Eye
          </h2>
          <div className="content-container">
            <div className="choose-direction">
              <DirectionModel setMessage={setMessage} />
            </div>
            {currentItem && (
              <div className="exam-item-details" style={{ width: '1000px' }}>
                <div>
                  <img
                    src={currentItem.url}
                    alt={`Exam item ${currentItem.id}`}
                    className="exam-item-image"
                    style={{
                      ...imageSizeStyle,
                      transform: `rotate(${currentItem.rotation}deg)`,
                      marginTop: '80px',
                      marginBottom: '130px'
                    }}
                  />
                </div>
                
                <div className="direction-buttons">
                  <button
                    className="direction-button"
                    style={{ backgroundColor: currentDirection === 'top' ? 'red' : '#007bff' }}
                    onClick={() => handleDirectionClick("top")}
                  >
                    Up
                  </button>
                  <button
                    className="direction-button"
                    style={{ backgroundColor: currentDirection === 'right' ? 'red' : '#007bff' }}
                    onClick={() => handleDirectionClick("right")}
                  >
                    Right
                  </button>
                  <button
                    className="direction-button"
                    style={{ backgroundColor: currentDirection === 'bottom' ? 'red' : '#007bff' }}
                    onClick={() => handleDirectionClick("bottom")}
                  >
                    Down
                  </button>
                  <button
                    className="direction-button"
                    style={{ backgroundColor: currentDirection === 'left' ? 'red' : '#007bff' }}
                    onClick={() => handleDirectionClick("left")}
                  >
                    Left
                  </button></div>
                <div className="info-container">
                  <p>
                    Number Of Test: {numberOfTest} - Level: {currentItem.level} - Diopter: {Sum()} <br/> Your Choose: {message}
                  </p>
                </div>
              </div>
            )}
            <div className="empty-div"></div>
          </div>
          <div className="footer">
            <button className="back-button" onClick={handleBackClick}>Back</button>
            <button className="next-button" onClick={toggleModal}>Finish</button>
          </div>
        </div>


      ) : (
        <div className="div3">
          <h2>Exam Results</h2>
          <p>Here are the exam results...</p>
          <div className="footer">
            <button className="back-button" onClick={handleBackClick}>Back</button>
          </div>
        </div>
      )}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              <h3>Finish Exam</h3>
              <p>Would you like to keep going?</p>
              <div className="modal-actions">
                <button className="btn btn-primary" onClick={handleSummitClick}>
                  Finish
                </button>
                <button className="btn btn-secondary" onClick={toggleModal}>
                  Keep Going
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showProfileModal && (

        <ProfileModal
          visible={showProfileModal}
          onCancel={toggleProfileModal}
          onSelectProfile={() => {
            toggleProfileModal();
          }}
          selectedExam={selectedExam}
          answers={answers}
          result={result}
          eyeSide={currentEye}
        />
      )}
    </div>

  );
};
export default ExamData;