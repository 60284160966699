// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABbQVDXEZK3ZQJdbA1btisXP1ViRv8Utw",
  authDomain: "visionup-ffdbc.firebaseapp.com",
  projectId: "visionup-ffdbc",
  storageBucket: "visionup-ffdbc.appspot.com",
  messagingSenderId: "981556788838",
  appId: "1:981556788838:web:e61d24f07d08889f87b7a7",
  measurementId: "G-K18ZRM4GJY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const imageDb = getStorage(app);